import { HandleStartGameSession } from './types';

export const handlePlayMusic = (audioElement: HTMLAudioElement | null) => {
  if (audioElement) {
    audioElement.play();
  }
};

export const handleStopMusic = (audioElement: HTMLAudioElement | null) => {
  if (audioElement) {
    audioElement.pause();
    audioElement.currentTime = 0;
  }
};

export const handleStartGameSession: HandleStartGameSession = ({
  setIsGameSessionActive,
  setActiveModal,
  setShowGameRules,
  setIsUserCurrentlyPlaying
}) => {
  setActiveModal(null);
  setIsGameSessionActive(true);
  setShowGameRules(true);

  setTimeout(() => {
    setShowGameRules(false);
  }, 3000);

  setTimeout(() => {
    setIsUserCurrentlyPlaying(true);
  }, 5000);
};
