import dayjs from 'dayjs';
import { FC, memo } from 'react';
import { useCountdownTimer } from 'react-easyrocket';
import { END_OF_OKTOBERFEST_DATE } from 'src/OktoberfestEvent/dates';
import { twJoin } from 'tailwind-merge';

interface CountdownTimerProps {
  className?: string;
}

const CountdownTimer: FC<CountdownTimerProps> = ({ className }) => {
  const endOfPromo = dayjs(
    `${END_OF_OKTOBERFEST_DATE.year}-${END_OF_OKTOBERFEST_DATE.month}-${END_OF_OKTOBERFEST_DATE.day}`
  ).unix();

  const { days, hours, minutes, seconds } = useCountdownTimer(endOfPromo);

  const countdownResult =
    days === '00' ? `${hours}h : ${minutes}m : ${seconds}s` : `${days}d : ${hours}h : ${minutes}m`;

  return (
    <div
      className={twJoin(
        'text-white text-center text-xs font-semibold p-1 backdrop-blur-xl rounded-[4px] bg-oktoberfest_timer_bg 1024:bg-[transparent] 1024:p-0 1024:text-left',
        className
      )}>
      {countdownResult}
    </div>
  );
};

export default memo(CountdownTimer);
