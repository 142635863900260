import { Button } from 'src/components/Buttons/Button';
import { useGetSinglePage } from 'react-easyrocket';
import { createStyles } from 'src/utils/createStyles';
import { twJoin } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';
import { scrollIntoViewWithHeaderOffset } from 'src/utils/scrollIntoViewWithOffset';
import { parseContent } from './utils';

export const Banner = () => {
  const { t } = useTranslation();
  const { data } = useGetSinglePage('catch_the_beer_banner');

  const { style, desktopBg, mobileBg } = createStyles({
    imageMobile: data?.imageMobile,
    imageDesktop: data?.image
  });

  const content = parseContent(data?.content);

  const handleButtonClick = () => {
    scrollIntoViewWithHeaderOffset(
      document.querySelector('.oktoberfest-game') as HTMLElement | null
    );
  };

  return (
    <section
      style={style}
      className={twJoin(
        'px-5 pb-5 pt-11 768:py-14 text-center flex flex-col items-center text-yellow bg-cover bg-no-repeat bg-center',
        desktopBg,
        mobileBg
      )}>
      <p className="text-xl font-bold 768:text-[24px] 768:leading-[110%]">{content}</p>
      <h2
        style={{
          textShadow: '0px 4px 0px #B72B00'
        }}
        className="text-4xl font-black mt-1 768:mt-2 768:text-[48px] 768:leading-[100%]">
        {data?.title}
      </h2>
      <p className="text-base font-medium text-white mt-3 768:mt-4 768:text-xl">
        {data?.description}
      </p>
      <Button
        mode="button-secondary"
        className="text-text-primary mt-10 768:mt-7"
        onClick={handleButtonClick}>
        {t('halloween.playGame')}
      </Button>
    </section>
  );
};
