import { FC, PropsWithChildren } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Skeletons } from 'src/components/Skeletons';
import { LinkTitleWrapper } from '../LinkTitleWrapper';
import { useTranslation } from 'react-i18next';
import { useGetSinglePage } from 'react-easyrocket';
import { CONTENT_PAGES } from 'src/constants';
import { replaceWithOktoberfestBigLogo } from 'src/event/oktoberfest/changeDefaultLogo';
import logo from 'src/assets/images/logoSidebar.webp';

export const LinkList: FC<PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation();
  const { childs: links = [], isLoading } = useGetSinglePage(CONTENT_PAGES.INFO);

  return (
    <div className="flex justify-between w-full items-top gap-5 flex-col 1024:flex-row items-center 1024:items-start">
      <Link
        className="w-auto pt-1 max-w-[115px] hidden 1024:block"
        to="/"
        aria-label="back to main">
        <img
          src={replaceWithOktoberfestBigLogo(logo)}
          className="max-w-[115px]"
          alt="jacktop logo"
        />
      </Link>

      {isLoading ? (
        <div className="w-full grid grid-cols-3 gap-2.5 grid-rows-3">
          <Skeletons className="h-6 w-[200px]" count={9} />
        </div>
      ) : (
        <div className="768:grid grid-cols-3 gap-2.5 w-full flex flex-col">
          {links.map((item, index) => (
            <NavLink
              aria-label={`${t(item.title)}`}
              to={`/info_pages/${item.code}`}
              key={index}
              className={({ isActive }) => (isActive ? 'text-yellow' : '')}>
              <LinkTitleWrapper>{item.title}</LinkTitleWrapper>
            </NavLink>
          ))}

          <NavLink
            to="/faq"
            aria-label={'Faq'}
            className={({ isActive }) => (isActive ? 'text-yellow' : '')}>
            <LinkTitleWrapper className="uppercase">{t('footer.faq')}</LinkTitleWrapper>
          </NavLink>
          <NavLink
            aria-label={`${t('footer.affiliate')}`}
            target="_blank"
            to="https://boomerang-partners.com/">
            <LinkTitleWrapper>{t('footer.affiliate')}</LinkTitleWrapper>
          </NavLink>
        </div>
      )}
      {children}
    </div>
  );
};
