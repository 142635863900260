import { useGetSinglePage } from 'react-easyrocket';

export const Terms = () => {
  const { data } = useGetSinglePage('catch_the_beer_terms');

  return (
    <div
      dangerouslySetInnerHTML={{ __html: data?.content }}
      className="cms-dropdown [&_strong]:font-bold text-white max-w-[700px] w-full"></div>
  );
};
