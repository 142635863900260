import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import logoMini from 'src/assets/images/logoMini.webp';
import { useAddToHomescreenPrompt } from 'src/hooks/useAddToHomescreenPrompt';
import { Button } from '../Buttons/Button';
import { Download, SimpleClose } from '../svg';
import { useDrawer } from 'src/hooks/useDrawer';
import { IS_PWA } from 'react-easyrocket';
import { replaceWithEuroMiniLogo } from 'src/event/EuroEvent/changeDefaultLogo';

export const PwaPopUp: FC = () => {
  const { t } = useTranslation();
  const [handleClick, isIos] = useAddToHomescreenPrompt();
  const { closeMobileAppSidebar, openMobileAppSidebar, isMobileAppPopupOpened } = useDrawer();

  useEffect(() => {
    if (IS_PWA) return;
    const lastClose = localStorage.getItem('timeHideMobilePopup');
    if (!lastClose) openMobileAppSidebar?.();
    if (Number(lastClose) > Date.now() + 24 * 3600 * 1000) {
      openMobileAppSidebar?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRedirectClick = () => {
    if (isIos) {
      handleClick?.();
      closeMobileAppSidebar?.();
    } else {
      handleClick?.();
      closeMobileAppSidebar?.();
    }
  };

  const handleCloseClick = () => {
    const now = Date.now();
    localStorage.setItem('timeHideMobilePopup', `${now}`);
    closeMobileAppSidebar?.();
  };

  if (!isMobileAppPopupOpened) return null;

  return (
    <div className=" top-0 bottom-0 left-0 right-0 h-auto w-auto bg-m_bg_shadowing z-[1000] jsm:flex hidden">
      <div className="bg-pp_bg w-full py-[7px] px-2.5 flex justify-between items-center gap-3">
        <div className="flex items-center justify-center w-[36px] h-[36px] rounded-[10px] bg-pp_logo_bg shadow-xl">
          <img src={replaceWithEuroMiniLogo(logoMini)} alt="logo" className="min-w-[34px]" />
        </div>
        <p className="text-center font-bold text-base">{t('mobileAppPopup.title')}</p>
        <div className="flex justify-center items-center gap-4">
          <Button
            onClick={handleRedirectClick}
            title={`${t('general.install')}`}
            label={`${t('general.install')}`}
            className="before:!rounded-[5px] after:!rounded-[5px] !rounded-[5px] !min-w-[100px] !w-auto !gap-1 !bg-pp_button_bg hover:!bg-pp_button_bg_hover !text-pp_button_text"
            leftIcon={<Download className="fill-pp_download" />}
          />
          <SimpleClose fill="var(--pp_icon)" onShow={handleCloseClick} />
        </div>
      </div>
    </div>
  );
};
