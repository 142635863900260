import CountdownTimer from './components/Countdown/index';
import BeerCup from './assets/beer_cup.webp';
import { Link, useLocation } from 'react-router-dom';
import { CrossCloseIcon } from 'src/components/svg/userMessageIcons';
import { Button } from 'src/components/Buttons/Button';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from 'react-easyrocket';
import { twJoin } from 'tailwind-merge';
import { useState } from 'react';
import { useDrawer } from 'src/hooks/useDrawer';

const hiddenPages = ['/account/', '/game/', '/catch-the-beer', '/sport'];

export const EventNotification = () => {
  const { t } = useTranslation();
  const [shouldShow, setShouldShow] = useState(true);
  const { isCookiesOpen } = useDrawer();
  const { pathname } = useLocation();

  const isHiddenByPage = hiddenPages.some((path) => pathname.includes(path));

  const handleClose = () => {
    setShouldShow(false);
  };
  const { isMobileLayout } = useWindowSize();

  if (!shouldShow || isHiddenByPage) {
    return null;
  }

  return (
    <div
      className={twJoin(
        'fixed 1024:gap-2.5 1024:py-2 1024:pr-10 1024:pl-4 rounded-[8px] 1024:flex-row 1024:bottom-5 right-3 z-50 flex flex-col items-center pt-6 1024:bg-OKTOBERFEST_DESKTOP_NOTIFICATION',
        isMobileLayout && '!bottom-[111px]',
        isCookiesOpen && '-translate-y-[54px]'
      )}>
      <button className="absolute top-1 right-1" onClick={handleClose}>
        <CrossCloseIcon className="fill-white w-4 h-4" />
      </button>
      <Link
        to="catch-the-beer"
        className="rounded-full 1024:hidden p-2 flex items-center justify-center border-2 border-game-badge-bonus bg-OKTOBERFEST_MOBILE_NOTIFICATION">
        <img
          src={BeerCup}
          alt="cup of beer"
          width={40}
          height={40}
          className="w-10 h-10 translate-x-0.5"
        />
      </Link>
      <img
        src={BeerCup}
        alt="cup of beer"
        width={60}
        height={60}
        className="w-[60px] h-[60px] hidden 1024:block"
      />
      <div>
        <p className="hidden 1024:block text-white text-xs ">
          {t('christmasTranslations.ourNewGame')}
        </p>
        <p className="hidden 1024:block text-yellow uppercase text-sm font-black">
          {t('catchGame.gameName')}
        </p>
        <CountdownTimer className="mt-1 1024:mt-0" />
        <Link to="catch-the-beer" className="hidden 1024:block">
          <Button
            label={t('general.more')}
            className="!w-[131px] !h-9 mt-2.5"
            mode="button-secondary"
          />
        </Link>
      </div>
    </div>
  );
};
