import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button } from 'src/components/Buttons/Button';
import projectileImage from '../images/gameFrame/projectile.webp';
import targetImage from '../images/gameFrame/target.webp';
import { AnimatePresence, motion } from 'framer-motion';
import Attempts from './Attempts';
import classNames from 'classnames';
import { useDeviceDetect, useWindowSize } from 'react-easyrocket';
import { useFullScreen } from 'src/hooks/useFullScreen';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';

interface GameBoardProps {
  showGameRules: boolean;
  isStartGame: boolean;
  attempts: number;
  isWinGame: boolean;
  handleUpdateAttempts: () => void;
  handleWinGame: () => void;
  isFullscreen: boolean;
}

export const GameBoard = ({
  showGameRules,
  isStartGame,
  attempts,
  isWinGame,
  handleUpdateAttempts,
  handleWinGame
}: GameBoardProps) => {
  const [currentAnimation, setCurrentAnimation] = useState('animate');
  const [position, setPosition] = useState(0);
  const [speed, setSpeed] = useState(21);
  const [projectileWidth, setProjectileWidth] = useState(200);
  const projectileRef = useRef<HTMLImageElement>(null);

  const { t } = useTranslation();

  const { is568 } = useWindowSize();
  const { width } = useWindowSize();
  const { isMobile } = useDeviceDetect();
  const { isFullscreenGameBoard, isHideHUD, refGameBoardFullscreen } = useFullScreen();

  const handleStopSanta = () => {
    if (attempts > 0) {
      handleUpdateAttempts();
      setCurrentAnimation('stop');
      const santaPosX = document.getElementById('santa-image')?.getBoundingClientRect().x || 0;
      const santaFadePosX =
        document.getElementById('santa-fade-image')?.getBoundingClientRect().x || 0;

      setPosition(santaPosX);

      if (santaFadePosX + 30 >= santaPosX && santaFadePosX - 30 <= santaPosX) {
        handleWinGame();
      }
    }

    setTimeout(() => {
      setCurrentAnimation('animate');
    }, 1250);
  };

  useEffect(() => {
    if (projectileRef?.current) {
      setProjectileWidth(projectileRef.current.width);
    }
    if (refGameBoardFullscreen && refGameBoardFullscreen?.current) {
      const gameFrameWidth = refGameBoardFullscreen?.current?.clientWidth;

      if (gameFrameWidth > 1000) {
        setSpeed(gameFrameWidth / 27);
        return;
      }
      if (gameFrameWidth > 768) {
        setSpeed(gameFrameWidth / 23);
        return;
      }
      if (gameFrameWidth > 428) {
        setSpeed(gameFrameWidth / 18);
        return;
      }
      setSpeed(gameFrameWidth / 14);
    }
  }, [width]);

  const generateRandomPositions = useCallback(() => {
    const positions = [window.innerWidth, -window.innerWidth - projectileWidth];

    const randomPositions = [];

    const previousPositions = [];
    for (let i = 0; i < 20; i++) {
      let randomIndex = Math.floor(Math.random() * positions.length);
      let randomPosition = positions[randomIndex];

      // Проверяем предыдущие позиции
      while (
        previousPositions.length >= 2 &&
        previousPositions.slice(-2).every((position) => position === randomPosition)
      ) {
        randomIndex = Math.floor(Math.random() * positions.length);
        randomPosition = positions[randomIndex];
      }

      randomPositions.push(randomPosition);
      previousPositions.push(randomPosition);

      // Ограничиваем длину предыдущих позиций до 2
      if (previousPositions.length > 2) {
        previousPositions.shift();
      }
    }

    return randomPositions;
  }, [projectileWidth]);

  const santaAnimation = useMemo(() => {
    return {
      animate: {
        x: generateRandomPositions(),
        y: '-50%',
        transition: {
          duration: speed,
          ease: 'linear',
          repeat: Infinity
        }
      },
      stop: {
        x: position - window.innerWidth / 2,
        y: '-50%',
        scale: 1.05,
        rotate: [0, -10, 10, -10, 0],
        transition: { duration: 0.5 }
      }
    };
  }, [position, speed]);

  return (
    <div className="flex flex-col items-center justify-between h-full w-full overflow-x-hidden">
      <AnimatePresence>
        {showGameRules && !isWinGame && (
          <motion.div
            className={classNames(
              'flex flex-col relative h-full w-full items-center justify-between',
              (isFullscreenGameBoard || isHideHUD) && isMobile && is568
                ? 'min-h-[200px]'
                : 'min-h-[320px]'
            )}
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}>
            <div className="relative flex-grow self-stretch">
              <img
                className={twMerge(
                  'h-[80%] w-auto absolute z-[1] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 '
                )}
                src={projectileImage}
                alt="santa"
                id="santa-fade-image"
              />
            </div>
            <Button
              mode="button-secondary"
              className="text-[18px] px-[50px] max-w-[300px] 768:px-[60px] !lowercase">
              {t('santa.tapToStop')}
            </Button>
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {isStartGame && !showGameRules && !isWinGame && (
          <motion.div
            className={classNames(
              'flex flex-col relative h-full w-full items-center justify-between',
              (isFullscreenGameBoard || isHideHUD) && isMobile && is568
                ? 'min-h-[200px]'
                : 'min-h-[320px]'
            )}
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.1 }}>
            <Attempts attempts={attempts} />
            <div className="relative flex-grow self-stretch">
              <motion.img
                ref={projectileRef}
                className={twMerge('h-[80%] absolute z-[2] left-1/2 top-1/2 w-auto')}
                src={projectileImage}
                alt="santa"
                variants={santaAnimation}
                animate={currentAnimation}
                initial={{ y: '-50%' }}
                id="santa-image"
              />
              <img
                className={twMerge(
                  'h-[80%] w-auto absolute z-[1] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 '
                )}
                src={targetImage}
                alt="santa"
                id="santa-fade-image"
              />
            </div>
            <Button
              mode="button-secondary"
              className="text-[18px] px-[50px] max-w-[300px] 768:px-[60px] !lowercase"
              onClick={handleStopSanta}>
              {t('santa.tapToStop')}
            </Button>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
