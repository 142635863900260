import { useGetSinglePage } from 'react-easyrocket';
import { PlayCard } from './components/PlayCard';
import classes from './styles.module.css';
import { twJoin } from 'tailwind-merge';

export const HowToPlay = () => {
  const { data } = useGetSinglePage('catch_the_beer_how_to_win');

  return (
    <section className="py-5 1024:py-10 overflow-hidden flex flex-col max-w-full items-center">
      <h2 className={twJoin(classes.title)} dangerouslySetInnerHTML={{ __html: data?.content }} />
      <div className="overflow-x-auto w-full mt-5">
        <div className="flex gap-4 justify-center w-min">
          {data?.childs?.map(({ code, title, description }) => (
            <PlayCard key={code} title={title} description={description} />
          ))}
        </div>
      </div>
    </section>
  );
};
