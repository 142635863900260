import { isProductionEnv } from 'src/api';

// API ENDPOINTS
export const GAME_ENDPOINT = {
  BANNER: 'catch_the_beer_how_to_play',
  GAME_END: 'catch_the_beer_time_end'
};

export const GAME_TIMER = 45;

export const CATCH_MODAL = {
  PLAY_GAME: 'PLAY_GAME' as const,
  WIN_GAME: 'WIN_GAME' as const,
  END_TIME_GAME: 'END_TIME_GAME' as const,
  UP_LEVEL: 'UP_LEVEL' as const,
  END_GAME: 'END_GAME' as const,
  LOSS_GAME: 'LOSS_GAME' as const,
  LOST_ALL_CHANCES: 'LOST_ALL_CHANCES' as const
};

export type CatchModal = keyof typeof CATCH_MODAL | null;

export const CATCH_GAME_KEY = 'CATCH_THE_BEER_2024';

export const HARDCORE_BONUS_ID: { [UTC_DAY: number]: number[] } = isProductionEnv
  ? {
      // Only production bonus ids
      23: [365, 366, 367, 368, 369, 370],
      24: [371, 372, 373, 374, 375, 376],
      25: [377, 378, 379, 380, 381, 382],
      26: [383, 384, 385, 386, 387, 388],
      27: [389, 390, 391, 392, 393, 394],
      28: [395, 396, 397, 398, 399, 400],
      29: [401, 402, 403, 404, 405, 406],
      30: [407, 408, 409, 410, 411, 412],
      1: [413, 414, 415, 416, 417, 418],
      2: [419, 420, 421, 422, 423, 424]
    }
  : {
      16: [674, 680, 681, 682, 683, 684],
      17: [674, 680, 681, 682, 683, 684],
      18: [674, 680, 681, 682, 683, 684],
      19: [674, 680, 681, 682, 683, 684],
      20: [674, 680, 681, 682, 683, 684],
      21: [674, 680, 681, 682, 683, 684],
      22: [674, 680, 681, 682, 683, 684],
      23: [674, 680, 681, 682, 683, 684],
      24: [674, 680, 681, 682, 683, 684],
      25: [674, 680, 681, 682, 683, 684],
      26: [674, 680, 681, 682, 683, 684],
      27: [674, 680, 681, 682, 683, 684],
      28: [674, 680, 681, 682, 683, 684],
      29: [674, 680, 681, 682, 683, 684],
      30: [674, 680, 681, 682, 683, 684],
      1: [674, 680, 681, 682, 683, 684],
      2: [674, 680, 681, 682, 683, 684]
    };

export const MIN_DEPOSIT_AMOUNT: any = {
  EUR: 30,
  USD: 30,
  NZD: 45,
  CHF: 30,
  AUD: 45,
  NOK: 300,
  CAD: 45,
  PLN: 150,
  RON: 150,
  DRL: 150,
  DKK: 225,
  SEK: 300,
  INR: 2700,
  ISK: 4500,
  CZK: 750,
  BRL: 150
};

export const SNOWFLAKES_AMOUNT_BY_DATE: { [day: number]: number } = {
  21: 75,
  22: 50,
  23: 75,
  24: 50,
  25: 75,
  26: 50,
  27: 75,
  29: 50,
  30: 75,
  1: 50,
  2: 75,
  4: 50,
  5: 75,
  6: 50,
  7: 75
};

export const catchDaysStructure = {
  16: {
    idBonusWin: HARDCORE_BONUS_ID[16],
    attempts: []
  },
  17: {
    idBonusWin: HARDCORE_BONUS_ID[17],
    attempts: []
  },
  18: {
    idBonusWin: HARDCORE_BONUS_ID[18],
    attempts: []
  },
  19: {
    idBonusWin: HARDCORE_BONUS_ID[19],
    attempts: []
  },
  20: {
    idBonusWin: HARDCORE_BONUS_ID[20],
    attempts: []
  },
  21: {
    idBonusWin: HARDCORE_BONUS_ID[21],
    attempts: []
  },
  22: {
    idBonusWin: HARDCORE_BONUS_ID[22],
    attempts: []
  },
  23: {
    idBonusWin: HARDCORE_BONUS_ID[23],
    attempts: []
  },
  24: {
    idBonusWin: HARDCORE_BONUS_ID[24],
    attempts: []
  },
  25: {
    idBonusWin: HARDCORE_BONUS_ID[25],
    attempts: []
  },
  26: {
    idBonusWin: HARDCORE_BONUS_ID[26],
    attempts: []
  },
  27: {
    idBonusWin: HARDCORE_BONUS_ID[27],
    attempts: []
  },
  28: {
    idBonusWin: HARDCORE_BONUS_ID[28],
    attempts: []
  },
  29: {
    idBonusWin: HARDCORE_BONUS_ID[29],
    attempts: []
  },
  30: {
    idBonusWin: HARDCORE_BONUS_ID[30],
    attempts: []
  },
  1: {
    idBonusWin: HARDCORE_BONUS_ID[1],
    attempts: []
  },
  2: {
    idBonusWin: HARDCORE_BONUS_ID[2],
    attempts: []
  }
};
