import { ForwardedRef, MouseEventHandler, ReactNode, forwardRef } from 'react';
import { Button } from 'src/components/Buttons/Button';
import { motion } from 'framer-motion';
import { MODAL_ANIMATION } from '../animations';
import { twJoin } from 'tailwind-merge';

interface GameBeerModalProps {
  onClick: MouseEventHandler<HTMLButtonElement>;
  buttonLabel: ReactNode;
  title: ReactNode;
  description: ReactNode;
  imgSrc: string;
  className?: string;
}

export const GameBeerModal = forwardRef(
  (
    { onClick, title, description, imgSrc, buttonLabel, className }: GameBeerModalProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    return (
      <motion.div
        ref={ref}
        {...MODAL_ANIMATION}
        className={twJoin(
          'w-[90%] max-w-[300px] bg-new-blue-bg rounded-xl overflow-hidden relative 768:w-full text-center',
          className
        )}>
        <img src={imgSrc} alt="" width={300} height={166} className="w-full aspect-[150/83]" />
        <div className="pt-2 pb-9 px-4 flex flex-col items-center">
          <h3 className="text-[20px] leading-[1.2] text-yellow text-center font-black uppercase">
            {title}
          </h3>
          <p className="mt-2.5 text-sm font-medium text-white">{description}</p>
          <Button mode="button-secondary" onClick={onClick} className="mt-2.5 !w-[190px]">
            {buttonLabel}
          </Button>
        </div>
      </motion.div>
    );
  }
);

GameBeerModal.displayName = 'GameBeerModal';
