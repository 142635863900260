import dayjs from 'dayjs';
import { DaysObjectStructure, GameDayStructure } from '../../types';
import { catchDaysStructure, HARDCORE_BONUS_ID } from '../../config';

const compareArrays = <T>(firstArray: T[], secondArray: T[]) => {
  if (firstArray.length !== secondArray.length) {
    return false;
  }
  for (let i = 0; i < secondArray.length; i++) {
    if (firstArray[i] !== secondArray[i]) {
      return false;
    }
  }
  return true;
};

export const substituteBonusesInStructure = (structureFromServer: DaysObjectStructure) => {
  const currentDayOfMonth = dayjs.utc().date();

  const isMissingCurrentDay = Boolean(
    !structureFromServer[currentDayOfMonth] &&
      catchDaysStructure[currentDayOfMonth as keyof typeof catchDaysStructure]
  );

  if (isMissingCurrentDay)
    return { shouldUpdateStructure: true, updatedStructure: catchDaysStructure };

  const currentDayObject: GameDayStructure = structureFromServer[currentDayOfMonth];
  const currentDayHardcodedBonuses = HARDCORE_BONUS_ID[currentDayOfMonth];

  const areBonusesEqual = compareArrays(currentDayHardcodedBonuses, currentDayObject.idBonusWin);

  if (areBonusesEqual) return { shouldUpdateStructure: false, updatedStructure: null };

  const updatedStructure: DaysObjectStructure = {
    ...structureFromServer,
    [currentDayOfMonth]: {
      idBonusWin: currentDayHardcodedBonuses,
      attempts: structureFromServer[currentDayOfMonth].attempts
    }
  };

  return { shouldUpdateStructure: true, updatedStructure };
};
