import classNames from 'classnames';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import SantaModalController from '../modals/SantaModalController';
import { GameBoard } from './GameBoard';
import FullScreenButton from './FullScreenButton';
import { useDeviceDetect, useWindowSize } from 'react-easyrocket';
import { useFullScreen } from 'src/hooks/useFullScreen';
import soundtrack from '../music/gameSoundtrack.mp3';
import { useCatchGameModals } from '../hooks/useCatchGameModals';
import { useAllowToPlay } from '../hooks/useAllowToPlay';
import { useInitLocalstorageStructure } from '../hooks/useInitLocalstorageStructure';
import { usePlayGame } from '../hooks/usePlayGame';
import { useGetReward } from '../hooks/useGetReward';
import { handleStartGameSession, handleStopMusic } from '../utils';
import backgroundImage from '../images/gameFrame/background.webp';
import { useTranslation } from 'react-i18next';
import { twJoin } from 'tailwind-merge';

const GameNew = () => {
  const { t } = useTranslation();
  const [isUserCurrentlyPlaying, setIsUserCurrentlyPlaying] = useState(false);
  const [isGameSessionActive, setIsGameSessionActive] = useState(false);
  const [attemptsLeftCount, setAttemptsLeftCount] = useState(3);
  const [isWinGame, setIsWinGame] = useState(false);
  const [showGameRules, setShowGameRules] = useState(false);
  const winModalRef = useRef<HTMLDivElement>(null);

  const audioRef = useRef<HTMLAudioElement | null>(null);

  const { isMobile } = useDeviceDetect();
  const { is568 } = useWindowSize();

  const {
    refGameBoardFullscreen,
    isFullscreenGameBoard,
    isHideHUD,
    isFullscreen,
    onFullscreenClose
  } = useFullScreen();

  const endGameSession = useCallback(() => {
    setIsUserCurrentlyPlaying(false);
    setIsGameSessionActive(false);
    onFullscreenClose();
    document.querySelector('.oktoberfest-game')?.scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    });
  }, []);

  const { isStructureReady } = useInitLocalstorageStructure();

  const allowanceToPlay = useAllowToPlay({ isStructureReady });

  const [activeModal, setActiveModal] = useCatchGameModals({
    attemptsLeftCount,
    isWinGame,
    allowanceToPlay,
    isGameSessionActive,
    endGameSession
  });

  const startGamingSession = useCallback(() => {
    handleStartGameSession({
      setActiveModal,
      setIsGameSessionActive,
      setShowGameRules,
      setIsUserCurrentlyPlaying
    });
  }, [setActiveModal]);

  const { handlePlayGame } = usePlayGame({
    onFullscreenClose,
    startGamingSession,
    audioRef
  });

  const { handleGetReward } = useGetReward({
    onFullscreenClose,
    setActiveModal,
    endGameSession,
    winModalRef,
    isFullscreen
  });

  useEffect(() => {
    if (!isGameSessionActive) {
      handleStopMusic(audioRef.current);
    }
  }, [isGameSessionActive]);

  const handleWinGame = useCallback(() => {
    setIsWinGame(true);
  }, []);

  const handleUpdateAttemptsCount = useCallback(() => {
    setAttemptsLeftCount((prev) => prev - 1);
  }, []);

  return (
    <div
      className={classNames(
        'bg-no-repeat bg-cover  mx-auto w-full flex items-start justify-center oktoberfest-game',
        !isMobile && '768:min-h-[700px] overflow-hidden',
        isHideHUD
          ? 'fixed top-0 right-0 bottom-0 left-0 h-[100vh] z-[9999]'
          : 'relative min-h-[510px] mb-[30px]',
        isFullscreenGameBoard || isHideHUD ? 'max-w-none' : 'max-w-[1240px]'
      )}
      style={{ backgroundImage: `url('${backgroundImage}')` }}
      ref={refGameBoardFullscreen}>
      <audio ref={audioRef} src={soundtrack} />
      <SantaModalController
        winModalRef={winModalRef}
        activeModal={activeModal}
        handlePlayGame={handlePlayGame}
        handleGetReward={handleGetReward}
      />
      <div
        className={twJoin(
          'w-full py-[20px] 768:py-[40px] flex flex-col items-center justify-between gap-[8px]',
          isMobile && 'landscape:gap-[15px] landscape:py-[10px]',
          (isHideHUD && is568) || (isFullscreen && is568) ? 'h-full' : 'h-[510px] 768:h-[650px]',
          isFullscreen && ''
        )}>
        <p
          style={{ textShadow: '0px 4px 0px #B72B00' }}
          className={twJoin(
            'text-2xl font-black text-yellow text-center 1024:text-5xl',
            showGameRules && !isWinGame && 'z-[4]'
          )}>
          {isGameSessionActive ? t('catchGame.oktoberQuest') : t('catchGame.gameName')}
        </p>
        <p
          className={twJoin(
            'text-white mt-1 text-[18px] leading-[1.2] font-bold text-center 1024:text-[25px]',
            isGameSessionActive && !showGameRules && 'hidden'
          )}>
          {t('catchGame.tries', { amount: attemptsLeftCount })}
        </p>
        <GameBoard
          showGameRules={showGameRules}
          isStartGame={isUserCurrentlyPlaying}
          attempts={attemptsLeftCount}
          handleUpdateAttempts={handleUpdateAttemptsCount}
          handleWinGame={handleWinGame}
          isWinGame={isWinGame}
          isFullscreen={isFullscreen}
        />

        <FullScreenButton />
      </div>
    </div>
  );
};

export default memo(GameNew);
