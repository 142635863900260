import { useEffect, useRef, useState } from 'react';
import { MessageType, MessagesContextType } from '../types';

export const useInitMessagesContext = ({
  debug = false
}: {
  debug?: boolean;
} = {}): MessagesContextType => {
  const messageHistory = useRef<MessageType[]>([]);
  const [newMessagesCount, setNewMessagesCount] = useState(0);
  const [openedMessages, setOpenedMessages] = useState<MessageType[]>([]);
  const messageTimers = useRef<Record<string, NodeJS.Timeout>>({}); // uuid -> timeoutId

  /** Открывает сообщение
   *
   * По умолчанию позволяет открывать любое количество одинаковых сообщений.
   * Если нужно предотвратить появление дубликатов - передайте preventDuplicates: true
   * и передайте id в которое поместите значение по которому будет производиться сравнение
   * для определения дубликатов. Для текстовых сообщений это может быть текст сообщения
   */
  const openMessage: MessagesContextType['openMessage'] = ({
    type,
    props,
    timeout = 5000,
    id,
    preventDuplicates = false,
    saveToHistory = true
  }) => {
    setOpenedMessages((messages) => {
      if (debug) console.log('[OPEN MESSAGE]:', { id, type, props, timeout, preventDuplicates });
      if (preventDuplicates) {
        const hasThisMessageAlready = messages.some((message) => message.id === id);
        if (hasThisMessageAlready) {
          if (debug)
            console.log('[OPEN MESSAGE PREVENTED]:', {
              id
            });
          return messages;
        }
      }
      const uuid = crypto.randomUUID();
      const message = { id, type, props, uuid };
      if (saveToHistory) {
        setNewMessagesCount((prev) => prev + 1);
        messageHistory.current = [...messageHistory.current, message];
      }
      scheduleTimeout(uuid, timeout);
      return [...messages, message];
    });
  };

  // * Сохроняет сообщения в историю */
  const saveToHistory: MessagesContextType['saveToHistory'] = (messages) => {
    messages?.forEach((message) => {
      // @ts-ignore
      const hasDuplicates = messageHistory.current.some((item) => item.id === message?.props?.text);

      if (hasDuplicates) return;
      setNewMessagesCount((prev) => prev + 1);

      messageHistory.current = [
        ...messageHistory.current,
        {
          // @ts-ignore
          id: message.props.text,
          type: message.type,
          props: message.props,
          uuid: crypto.randomUUID()
        }
      ];
    });
  };

  //* Контролирует показ лейбла */
  const resetMessagesCount = () => {
    setNewMessagesCount(0);
  };

  /** Закрывает сообщение с заданным uuid и очищает таймаут */
  const closeMessage = (uuid: string) => {
    setOpenedMessages((messages) => messages.filter((message) => message.uuid !== uuid));
    clearTimer(uuid);
  };

  /** Очищает таймаут и удаляет запись из объекта таймаутов */
  function clearTimer(uuid: string) {
    clearTimeout(messageTimers.current[uuid]);
    delete messageTimers.current[uuid];
  }

  /** Устанавливает таймаут для сообщения */
  function scheduleTimeout(uuid: string, timeout: number) {
    messageTimers.current[uuid] = setTimeout(() => {
      closeMessage(uuid);
    }, timeout);
  }

  if (debug) {
    console.log('[MESSAGES]: ', openedMessages, messageTimers.current);
  }

  // Эффект очищающий все таймауты
  useEffect(() => {
    const timers = messageTimers.current;
    return () => {
      Object.values(timers).forEach((timeoutId) => clearTimeout(timeoutId));
    };
  }, []);

  return {
    messageHistory: messageHistory.current,
    openedMessages,
    openMessage,
    closeMessage,
    resetMessagesCount,
    newMessagesCount,
    saveToHistory
  };
};
