import { useTranslation } from 'react-i18next';
import Moon from 'src/assets/images/moon.webp';
import Wizard from 'src/assets/images/wizard.webp';

import './styles.css';
import { replaceWithEuroBigLogo } from 'src/event/EuroEvent/changeDefaultLogo';

export const BlockedCountryPlug = () => {
  const { t } = useTranslation();

  return (
    <div className="blocked-country-wrapper 1024:px-0 px-[24px]">
      <img src={replaceWithEuroBigLogo(Moon)} className="moon-image" alt="moon" />
      <p className="blocked-country-subtitle">
        Ooops... <span className="blocked-country-subtitle">{t('general.blockedCountry')}</span>
      </p>

      <img src={Wizard} className="wizard-image" alt="wizard" />
    </div>
  );
};
