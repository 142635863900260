import { FC, ReactNode } from 'react';

import { twJoin } from 'tailwind-merge';
import { DatePicker } from './components/DatePicker';
import { PickerPosition } from './components/DatePicker/types';
import { getHiddenInputValue } from './utils';

export interface InputProps {
  label?: ReactNode;
  labelWithPadding?: boolean;
  labelClassName?: string;
  buttonClassName?: string;
  pickerPosition?: PickerPosition;
  name?: string;
  value: string;
  onChange: (value: string) => void;
  onBlur?: () => void;
  placeholder?: string;
  displayedValueFormat?: string;
  isFullScreenDesktop?: boolean;
}

export const InputDate: FC<InputProps> = ({
  value,
  onChange,
  onBlur,
  placeholder,
  label,
  labelWithPadding,
  labelClassName,
  displayedValueFormat,
  name,
  pickerPosition,
  buttonClassName,
  isFullScreenDesktop
}) => {
  return (
    <div className="flex flex-col justify-center w-full">
      {label && (
        <label
          className={twJoin(
            'text-text-secondary mb-2',
            labelWithPadding && 'pl-5',
            labelClassName
          )}>
          {label}
        </label>
      )}
      <DatePicker
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
        displayedValueFormat={displayedValueFormat}
        pickerPosition={pickerPosition}
        buttonClassName={buttonClassName}
        isFullScreenDesktop={isFullScreenDesktop}
      />
      {name && (
        <input type="text" className="hidden" name={name} value={getHiddenInputValue(value)} />
      )}
    </div>
  );
};
