import { t } from 'i18next';
import { CSSProperties, memo, useState } from 'react';
import { Button } from 'src/components/Buttons/Button';
import { GAME_ENDPOINT } from '../config';
import { motion } from 'framer-motion';
import { useGetSinglePage } from 'react-easyrocket';
import { twJoin } from 'tailwind-merge';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper';
import styles from './styles.module.css';
import { CONTENT_URL } from 'src/api';
import { useTranslation } from 'react-i18next';

interface SantaModalPlayGameProps {
  handlePlayGame: () => void;
}

const ModalPlayGame = ({ handlePlayGame }: SantaModalPlayGameProps) => {
  const { data } = useGetSinglePage(GAME_ENDPOINT.BANNER);
  const [activeIndex, setActiveIndex] = useState(0);
  const {
    i18n: { language }
  } = useTranslation();

  const style = {
    '--backgroundImage': `url(${CONTENT_URL}${data?.childs?.[activeIndex]?.imageMobile})`
  } as CSSProperties;

  const backgroundImage = 'bg-[image:var(--backgroundImage)]';

  return (
    <motion.div
      className={twJoin(
        'flex flex-col items-center justify-center w-[90%] max-w-[300px] bg-new-blue-bg rounded-[10px] relative  768:w-full',
        language === 'el' && 'translate-y-3'
      )}>
      <div
        className={twJoin(
          'w-full flex justify-center pt-20 pb-8 aspect-[300/162] bg-cover bg-center',
          backgroundImage
        )}
        style={style}>
        <Button
          mode="button-secondary"
          onClick={handlePlayGame}
          className={twJoin('max-w-full text-xl py-4 !w-[190px]', activeIndex === 1 && '!hidden')}>
          {t('general.play')}
        </Button>
      </div>
      <div className="pt-2 px-4 pb-5 w-full">
        <h3 className="text-[20px] text-yellow font-black text-center uppercase leading-[1.2]">
          {data?.title}
        </h3>
        <Swiper
          className="w-full mt-2.5"
          modules={[Pagination, Navigation]}
          pagination={{
            el: '.play-modal-pagination',
            clickable: true
          }}
          navigation={{
            nextEl: '.next-btn-modal',
            prevEl: '.prev-btn-modal'
          }}
          onSlideChange={(swiper) => {
            setActiveIndex(swiper.activeIndex);
          }}
          slidesPerView={1}>
          {data?.childs?.map(({ content, code }) => (
            <SwiperSlide key={code}>
              <div
                dangerouslySetInnerHTML={{ __html: content }}
                className="text-white text-sm font-medium text-center [&_strong]:text-yellow [&_strong]:font-bold"
              />
            </SwiperSlide>
          ))}
          <div className="mt-6 flex items-center justify-center gap-2">
            <button className="prev-btn-modal">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="16"
                viewBox="0 0 17 16"
                fill="none">
                <path
                  d="M8.66699 16C13.0783 16 16.667 12.4113 16.667 8C16.667 3.58866 13.0783 -3.1373e-07 8.66699 -6.99382e-07C4.25565 -1.08503e-06 0.666993 3.58866 0.666993 8C0.666993 12.4113 4.25565 16 8.66699 16ZM6.19565 7.52866L9.52899 4.19534C9.65899 4.06534 9.82965 4 10.0003 4C10.171 4 10.3417 4.06534 10.4717 4.19534C10.7323 4.456 10.7323 4.87734 10.4717 5.138L7.60965 8L10.4716 10.862C10.7323 11.1227 10.7323 11.544 10.4716 11.8047C10.211 12.0653 9.78965 12.0653 9.52899 11.8047L6.19565 8.47131C5.93499 8.21066 5.93499 7.78934 6.19565 7.52866Z"
                  fill="#4D3DA4"
                />
                <path
                  d="M6.19565 7.52866L9.52899 4.19534C9.65899 4.06534 9.82965 4 10.0003 4C10.171 4 10.3417 4.06534 10.4717 4.19534C10.7323 4.456 10.7323 4.87734 10.4717 5.138L7.60965 8L10.4716 10.862C10.7323 11.1227 10.7323 11.544 10.4716 11.8047C10.211 12.0653 9.78965 12.0653 9.52899 11.8047L6.19565 8.47131C5.93499 8.21066 5.93499 7.78934 6.19565 7.52866Z"
                  fill="white"
                />
              </svg>
            </button>
            <div className={twJoin('play-modal-pagination', styles.pagination)} />
            <button className="next-btn-modal">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="16"
                viewBox="0 0 17 16"
                fill="none">
                <path
                  d="M8.33398 0C3.92264 0 0.333984 3.58866 0.333984 8C0.333984 12.4113 3.92264 16 8.33398 16C12.7453 16 16.334 12.4113 16.334 8C16.334 3.58866 12.7453 0 8.33398 0ZM10.8053 8.47134L7.47198 11.8047C7.34198 11.9347 7.17133 12 7.00064 12C6.82998 12 6.6593 11.9347 6.5293 11.8047C6.26864 11.544 6.26864 11.1227 6.5293 10.862L9.39133 8L6.52933 5.138C6.26867 4.87734 6.26867 4.456 6.52933 4.19534C6.78998 3.93469 7.21133 3.93469 7.47198 4.19534L10.8053 7.52869C11.066 7.78934 11.066 8.21066 10.8053 8.47134Z"
                  fill="#4D3DA4"
                />
                <path
                  d="M10.8053 8.47134L7.47198 11.8047C7.34198 11.9347 7.17133 12 7.00064 12C6.82998 12 6.6593 11.9347 6.5293 11.8047C6.26864 11.544 6.26864 11.1227 6.5293 10.862L9.39133 8L6.52933 5.138C6.26867 4.87734 6.26867 4.456 6.52933 4.19534C6.78998 3.93469 7.21133 3.93469 7.47198 4.19534L10.8053 7.52869C11.066 7.78934 11.066 8.21066 10.8053 8.47134Z"
                  fill="white"
                />
              </svg>
            </button>
          </div>
        </Swiper>
      </div>
    </motion.div>
  );
};

export default memo(ModalPlayGame);
