import { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFullScreen } from 'src/hooks/useFullScreen';
import { useTranslation } from 'react-i18next';
import { GameBeerModal } from './GameBeerModal';
import winImage from '../images/modals/all-chances.webp';

const ModalLostAllChances = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { onFullscreenClose } = useFullScreen();

  const handleRedirect = () => {
    onFullscreenClose();
    navigate('/games/slots');
  };

  return (
    <GameBeerModal
      title={t('gameModals.usedAll')}
      description={t('gameModals.tryTomorrow')}
      onClick={handleRedirect}
      imgSrc={winImage}
      buttonLabel={t('halloween.redirectToSlots')}
    />
  );
};

export default memo(ModalLostAllChances);
