import { memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { GameBeerModal } from './GameBeerModal';
import defeat from '../images/modals/defeat.webp';
import { getCurrencyIcon, useCurrency } from 'react-easyrocket';
import { MIN_DEPOSIT_AMOUNT } from '../config';
import { useModalContext } from 'src/hooks/useModalContext';
import { useFullScreen } from 'src/hooks/useFullScreen';

const ModalLossGame = () => {
  const { t } = useTranslation();
  const { currencyName, currencyIcon } = useCurrency();
  const { openModal } = useModalContext();
  const { onFullscreenClose } = useFullScreen();

  const minAmount = MIN_DEPOSIT_AMOUNT[currencyName]
    ? `${currencyIcon} ${MIN_DEPOSIT_AMOUNT[currencyName]}`
    : `${getCurrencyIcon('EUR')} ${MIN_DEPOSIT_AMOUNT['EUR']}`;

  const handleClick = () => {
    onFullscreenClose();
    openModal('POPUP_DEPOSIT');
  };

  return (
    <GameBeerModal
      title={t('gameModals.defeat')}
      description={
        <Trans
          i18nKey={'catchGame.spentAllYellow'}
          values={{
            currency: minAmount
          }}
          components={{
            yellow: <span className="text-yellow" />
          }}
        />
      }
      onClick={handleClick}
      imgSrc={defeat}
      buttonLabel={t('general.depositplay')}
    />
  );
};

export default memo(ModalLossGame);
