import { Dispatch, RefObject, SetStateAction, useCallback } from 'react';
import { useActivateBonusViaLink, useGetLocalstorage } from 'react-easyrocket';
import { CATCH_GAME_KEY, CatchModal } from '../../config';
import { DaysObjectStructure } from '../../types';

export const useGetReward = ({
  onFullscreenClose,
  setActiveModal,
  endGameSession,
  winModalRef,
  isFullscreen
}: {
  onFullscreenClose: () => void;
  setActiveModal: Dispatch<SetStateAction<CatchModal>>;
  endGameSession: () => void;
  winModalRef: RefObject<HTMLDivElement>;
  isFullscreen: boolean;
}) => {
  const { value } = useGetLocalstorage({ key: CATCH_GAME_KEY });
  const { mutateAsync } = useActivateBonusViaLink();

  const handleGetReward = useCallback(async () => {
    if (!JSON.parse(value)) return;
    if (isFullscreen) {
      onFullscreenClose();
      winModalRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    }

    const daysObject: DaysObjectStructure = JSON.parse(value);
    const currentDate = new Date();
    const currentDayOfMoth = currentDate.getUTCDate();

    const attemptsDone = daysObject[currentDayOfMoth].attempts.length;

    const idBonusToActivate = daysObject[currentDayOfMoth].idBonusWin[attemptsDone - 1];

    await mutateAsync({ bonus_id: idBonusToActivate.toString() });
    setActiveModal(null);

    endGameSession();
  }, [endGameSession, mutateAsync, onFullscreenClose, setActiveModal, value, isFullscreen]);

  return { handleGetReward };
};
