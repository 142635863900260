export const HEADER_MAIN_HEIGHT = 50;

export const ALLOWANCE_TO_PLAY_STATUS = {
  UNAUTHORIZED: 'UNAUTHORIZED',
  LOADING: 'LOADING',
  FETCH_ERROR: 'FETCH_ERROR',
  ZERO_USER_LEVEL: 'ZERO_USER_LEVEL',
  REACHED_ATTEMPTS_LIMIT: 'REACHED_ATTEMPTS_LIMIT',
  NO_TRANSACTION_AFTER_TIMESTAMP: 'NO_TRANSACTION_AFTER_TIMESTAMP'
};
