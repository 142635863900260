import { RefObject, memo, useEffect } from 'react';
import winImage from '../images/modals/win-game.webp';
import { Trans, useTranslation } from 'react-i18next';
import { GameBeerModal } from './GameBeerModal';
import { useFullScreen } from 'src/hooks/useFullScreen';
import { useNavigate } from 'react-router-dom';

interface SantaModalWinGameProps {
  handleGetReward: () => void;
  winModalRef?: RefObject<HTMLDivElement>;
}

const ModalWinGame = ({ handleGetReward, winModalRef }: SantaModalWinGameProps) => {
  const { t } = useTranslation();
  const { onFullscreenClose } = useFullScreen();
  const navigate = useNavigate();

  useEffect(() => {
    handleGetReward();
  }, []);

  const handleClick = () => {
    onFullscreenClose();
    navigate('/account/promo');
  };

  return (
    <GameBeerModal
      ref={winModalRef}
      title={t('tournaments.congratulations')}
      description={
        <Trans
          i18nKey="catchGame.gameReward"
          components={{ bold: <span className="font-bold text-yellow" /> }}
        />
      }
      imgSrc={winImage}
      onClick={handleClick}
      buttonLabel={t('general.getReward')}
    />
  );
};

export default memo(ModalWinGame);
