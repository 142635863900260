import { CheckEventDatesParams, checkEventDatesFromEnv } from 'src/utils/checkEventDates';

const END_OF_OLYMPIC_DATE = {
  year: '2024',
  month: '08',
  day: '11'
};

const START_OF_OKTOBER_DEV_FEST_DATE = {
  year: '2024',
  month: '09',
  day: '08'
};

const START_OF_OKTOBER_PROD_FEST_DATE = {
  year: '2024',
  month: '09',
  day: '23'
};

const END_OF_OKTOBER_FEST_DATE = {
  year: '2024',
  month: '10',
  day: '02'
};

export const OKTOBER_FEST_DATES_PROD: CheckEventDatesParams = {
  startDate: START_OF_OKTOBER_PROD_FEST_DATE,
  endDate: END_OF_OKTOBER_FEST_DATE
};

export const OKTOBER_FEST_DATES_DEV: CheckEventDatesParams = {
  startDate: START_OF_OKTOBER_DEV_FEST_DATE,
  endDate: END_OF_OKTOBER_FEST_DATE
};

export const OLYMPIC_LOGO_DATES_PROD: CheckEventDatesParams = {
  startDate: {
    year: '2024',
    month: '07',
    day: '24'
  },
  endDate: END_OF_OLYMPIC_DATE
};

export const OLYMPIC_LOGO_DATES_DEV: CheckEventDatesParams = {
  startDate: {
    year: '2024',
    month: '07',
    day: '24'
  },
  endDate: END_OF_OLYMPIC_DATE
};

export const OLYMPIC_CATEGORY_DATES_DEV: CheckEventDatesParams = {
  startDate: {
    year: '2024',
    month: '07',
    day: '24'
  },
  endDate: END_OF_OLYMPIC_DATE
};

export const OLYMPIC_CATEGORY_DATES_PROD: CheckEventDatesParams = {
  startDate: {
    year: '2024',
    month: '07',
    day: '24'
  },
  endDate: END_OF_OLYMPIC_DATE
};

export const shouldShowEuroLogo = checkEventDatesFromEnv({
  devDates: OLYMPIC_LOGO_DATES_DEV,
  prodDates: OLYMPIC_LOGO_DATES_PROD
});

export const shouldShowOlympicLogo = checkEventDatesFromEnv({
  devDates: OLYMPIC_LOGO_DATES_DEV,
  prodDates: OLYMPIC_LOGO_DATES_PROD
});

export const shouldShowOlympicCategory = checkEventDatesFromEnv({
  devDates: OLYMPIC_CATEGORY_DATES_DEV,
  prodDates: OLYMPIC_CATEGORY_DATES_PROD
});

export const shouldShowOktoberfest = checkEventDatesFromEnv({
  devDates: OKTOBER_FEST_DATES_DEV,
  prodDates: OKTOBER_FEST_DATES_PROD
});
