import { useEffect, useState } from 'react';
import { CATCH_MODAL, CatchModal } from '../../config';
import { UseCatchGameModals } from './types';
import { ALLOWANCE_TO_PLAY_STATUS } from '../../constants';

export const useCatchGameModals: UseCatchGameModals = ({
  attemptsLeftCount,
  isWinGame,
  allowanceToPlay,
  isGameSessionActive,
  endGameSession
}) => {
  const [activeModal, setActiveModal] = useState<CatchModal>(null);

  useEffect(() => {
    if (allowanceToPlay.status === ALLOWANCE_TO_PLAY_STATUS.UNAUTHORIZED) {
      setActiveModal(CATCH_MODAL.PLAY_GAME);
      return;
    }

    if (allowanceToPlay.status === ALLOWANCE_TO_PLAY_STATUS.LOADING) {
      return;
    }

    if (allowanceToPlay.status === ALLOWANCE_TO_PLAY_STATUS.ZERO_USER_LEVEL) {
      setActiveModal(CATCH_MODAL.UP_LEVEL);
      return;
    }

    if (attemptsLeftCount === 0 && !isWinGame) {
      setActiveModal(CATCH_MODAL.LOSS_GAME);
      endGameSession();
      return;
    }
    if (
      allowanceToPlay.status === ALLOWANCE_TO_PLAY_STATUS.REACHED_ATTEMPTS_LIMIT &&
      !isWinGame &&
      !isGameSessionActive
    ) {
      setActiveModal(CATCH_MODAL.LOST_ALL_CHANCES);
      return;
    }

    if (
      !allowanceToPlay.isAllowedToPlay &&
      !isGameSessionActive &&
      attemptsLeftCount !== 0 &&
      !isWinGame
    ) {
      setActiveModal(CATCH_MODAL.END_GAME);
      endGameSession();
      return;
    }

    if (!isGameSessionActive && allowanceToPlay.isAllowedToPlay) {
      setActiveModal(CATCH_MODAL.PLAY_GAME);
      return;
    }

    if (isWinGame) {
      setActiveModal(CATCH_MODAL.WIN_GAME);
      return;
    }
  }, [allowanceToPlay, attemptsLeftCount, endGameSession, isGameSessionActive, isWinGame]);

  return [activeModal, setActiveModal];
};
