import { t } from 'i18next';
import { GameBeerModal } from './GameBeerModal';
import leveUp from '../images/modals/level-up.webp';
import { useModalContext } from 'src/hooks/useModalContext';
import { useFullScreen } from 'src/hooks/useFullScreen';

export const ModalUpLevel = () => {
  const { openModal } = useModalContext();
  const { onFullscreenClose } = useFullScreen();

  const handleClick = () => {
    onFullscreenClose();
    openModal('POPUP_DEPOSIT');
  };

  return (
    <GameBeerModal
      className="mt-6"
      title={t('gameModals.needUpLevel')}
      description={t('gameModals.upYourLevelText')}
      onClick={handleClick}
      buttonLabel={t('general.depositplay')}
      imgSrc={leveUp}
    />
  );
};
