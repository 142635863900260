import { MutableRefObject, useCallback } from 'react';
import { useApiContext, useGetLocalstorage, usePutLocalstorage } from 'react-easyrocket';
import { useModalContext } from 'src/hooks/useModalContext';
import { handlePlayMusic } from '../../utils';
import { CATCH_GAME_KEY } from '../../config';
import dayjs from 'dayjs';
import { DaysObjectStructure } from '../../types';

export const usePlayGame = ({
  onFullscreenClose,
  startGamingSession,
  audioRef
}: {
  onFullscreenClose: () => void;
  startGamingSession: () => void;
  audioRef: MutableRefObject<HTMLAudioElement | null>;
}) => {
  const { isAuthenticated } = useApiContext();
  const { openModal } = useModalContext();
  const { value } = useGetLocalstorage({ key: CATCH_GAME_KEY });
  const { mutateAsync: updateStorage } = usePutLocalstorage();

  const handlePlayGame = useCallback(async () => {
    if (!isAuthenticated) {
      onFullscreenClose();
      openModal('SIGNUP', { props: { onSignupSuccess: () => {} } });
      return;
    }

    if (!value || !JSON.parse(value)) return;

    handlePlayMusic(audioRef.current);

    const gameIds: DaysObjectStructure = JSON.parse(value);
    const currentDay = dayjs.utc().date();

    gameIds[currentDay].attempts.push('TIMESTAMP');
    await updateStorage({ key: CATCH_GAME_KEY, value: JSON.stringify(gameIds) });

    startGamingSession();
  }, [
    audioRef,
    isAuthenticated,
    onFullscreenClose,
    openModal,
    startGamingSession,
    updateStorage,
    value
  ]);

  return {
    handlePlayGame
  };
};
