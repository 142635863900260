import { GAME_TIMER } from '../config';

// MODAL OPEN/CLOSE ANIMATION
export const MODAL_OVERLAY_ANIMATION = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 }
};

export const MODAL_ANIMATION = {
  initial: { scale: 0 },
  animate: { scale: 1 },
  transition: {
    duration: 0.2,
    type: 'spring',
    damping: 25,
    stiffness: 200
  }
};

// ClOCK SPIN ANIMATION
export const CLOCK_VARIANTS = {
  animate: {
    rotate: 1080,
    transition: { duration: GAME_TIMER }
  }
};

// GAME CARD ANIMATIONS
export const ANGRY_HOVER_GAME_CARD = {
  scale: 1.05,
  rotate: [0, -10, 10, -10, 0],
  transition: { duration: 0.5 }
};

export const getCardAnimationProps = (showGameCards: boolean, i: number) => {
  const animationProps = {
    initial: 'hidden',
    animate: showGameCards ? 'visible' : 'hidden',
    variants: {
      hidden: { opacity: 0, scale: 0 },
      visible: { opacity: 1, scale: 1 }
    },
    transition: { delay: i * 0.1 }
  };

  return animationProps;
};

// FADE ANIMATION
export const FADE_ANIMATION = {
  initial: { opacity: 0 },
  animate: { opacity: 1 }
};
