import { Banner } from './components/Banner';
import GameNew from './components/CatchGame/game/GameNew';
import { HowToPlay } from './components/HowToPlay';
import { Terms } from './components/Terms';

export const GameBeer = () => {
  return (
    <>
      <Banner />
      <div className="flex flex-col items-center px-2.5 768:px-5">
        <HowToPlay />
        <GameNew />
        <Terms />
      </div>
    </>
  );
};
