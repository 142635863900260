import { useGetLocalstorage, usePutLocalstorage } from 'react-easyrocket';
import { CATCH_GAME_KEY, catchDaysStructure } from '../../config';
import { useEffect, useState } from 'react';
import { DaysObjectStructure } from '../../types';
import { substituteBonusesInStructure } from './utils';

export const useInitLocalstorageStructure = () => {
  const [isStructureReady, setIsStructureReady] = useState(false);
  const { value, isSuccess, isFetching } = useGetLocalstorage({ key: CATCH_GAME_KEY });
  const { mutateAsync } = usePutLocalstorage();

  useEffect(() => {
    (async () => {
      if (!isSuccess || isFetching) return;

      const parsedData: DaysObjectStructure | null = JSON.parse(value);

      if (parsedData === null) {
        await mutateAsync({ key: CATCH_GAME_KEY, value: JSON.stringify(catchDaysStructure) });
        return;
      }

      const { shouldUpdateStructure, updatedStructure } = substituteBonusesInStructure(parsedData);

      if (shouldUpdateStructure && updatedStructure) {
        await mutateAsync({ key: CATCH_GAME_KEY, value: JSON.stringify(updatedStructure) });
        return;
      }

      setIsStructureReady(true);
    })();
  }, [isFetching, isSuccess, mutateAsync, value]);
  return { isStructureReady };
};
