import { FC } from 'react';
import { Link } from 'react-router-dom';
import { passAffiliateParams } from 'react-easyrocket';
import { SOCIAL_ICONS } from '../config';
import { useTranslation } from 'react-i18next';
import { twJoin } from 'tailwind-merge';

interface SocialMediaLinkProps {
  name: string;
  path: string;
  isCollapsed?: boolean;
}

export const SocialMediaLink: FC<SocialMediaLinkProps> = ({ name, path, isCollapsed }) => {
  const {
    i18n: { language }
  } = useTranslation();
  const affiliateParams = passAffiliateParams();

  const url = new URL(path);

  const params = new URLSearchParams(url.search);

  params.append('lang', language);
  Object.entries(affiliateParams).forEach(([key, value]) => {
    params.append(key, value);
  });

  const icon = SOCIAL_ICONS[name as keyof typeof SOCIAL_ICONS];

  return (
    <Link
      referrerPolicy="origin"
      to={`${url.origin.toString()}${url.pathname.toString()}?${params.toString()}`}
      className="flex text-base font-normal transition-colors duration-300 bg-background-white-transparent text-white rounded-[35px] justify-start h-10 p-2 items-center  border cursor-pointer hover:border-yellow hover:text-yellow">
      <img
        height={24}
        width={24}
        className={twJoin('h-6 w-6', !isCollapsed && 'mr-2.5')}
        src={icon || SOCIAL_ICONS['Microsoft']}
      />
      {!isCollapsed && name}
    </Link>
  );
};
