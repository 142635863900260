import { memo } from 'react';
import { GameBeerModal } from './GameBeerModal';
import usedChance from '../images/modals/used-chance.webp';
import { Trans, useTranslation } from 'react-i18next';
import { MIN_DEPOSIT_AMOUNT } from '../config';
import { getCurrencyIcon, useCurrency } from 'react-easyrocket';
import { useModalContext } from 'src/hooks/useModalContext';
import { useFullScreen } from 'src/hooks/useFullScreen';

const ModalEndGame = () => {
  const { t } = useTranslation();
  const { openModal } = useModalContext();
  const { onFullscreenClose } = useFullScreen();

  const handleClick = () => {
    onFullscreenClose();
    openModal('POPUP_DEPOSIT');
  };

  const { currencyName, currencyIcon } = useCurrency();

  const minAmount = MIN_DEPOSIT_AMOUNT[currencyName]
    ? `${currencyIcon}${MIN_DEPOSIT_AMOUNT[currencyName]}`
    : `${getCurrencyIcon('EUR')}${MIN_DEPOSIT_AMOUNT['EUR']}`;

  return (
    <GameBeerModal
      title={t('halloween.usedChance')}
      description={
        <Trans
          i18nKey={'catchGame.timesUpTextBold'}
          components={{
            bold: <span className="font-bold text-yellow" />
          }}
          values={{
            amount: minAmount
          }}
        />
      }
      onClick={handleClick}
      imgSrc={usedChance}
      buttonLabel={t('general.depositplay')}
    />
  );
};

export default memo(ModalEndGame);
