import dayjs from 'dayjs';
import { useEffect, useMemo } from 'react';
import {
  useApiContext,
  useCurrency,
  useGetFullInfo,
  useGetLocalstorage,
  useGetTransactions
} from 'react-easyrocket';
import { CATCH_GAME_KEY } from '../../config';
import { DaysObjectStructure } from '../../types';
import { allowToPlay, checkForFullfillingDepositInTransactions, forbidPlay } from './utils';
import { ALLOWANCE_TO_PLAY_STATUS } from '../../constants';

export const useAllowToPlay = ({ isStructureReady }: { isStructureReady: boolean }) => {
  const { currencyName } = useCurrency();
  const { isAuthenticated } = useApiContext();
  const { fullInfo, isLoading: isLoadingFullInfo, isSuccess: isSuccessFullInfo } = useGetFullInfo();
  const {
    value,
    isFetching,
    isSuccess: isSuccessLocalstorage,
    refetch: refetchLocalstorage
  } = useGetLocalstorage({ key: CATCH_GAME_KEY });
  const {
    transactions,
    isSuccess: isSuccessTransactions,
    isLoading: isLoadingTransaction
  } = useGetTransactions(
    {
      date_start: dayjs.utc().format('YYYY-MM-DD'),
      date_end: dayjs.utc().add(1, 'day').format('YYYY-MM-DD'),
      type: ['Deposit'],
      status: ['Complete', 'PendingWager', 'Rejected', 'WagerZeroing'],
      page: 1
    },
    { disableCurrency: true, target: 'christmas-spins' }
  );

  useEffect(() => {
    if (isAuthenticated) {
      refetchLocalstorage();
    }
  }, [isAuthenticated, refetchLocalstorage]);

  const allowanceToPlay = useMemo(() => {
    if (!isAuthenticated) {
      return forbidPlay(ALLOWANCE_TO_PLAY_STATUS.UNAUTHORIZED);
    }

    if (isFetching || isLoadingTransaction || !isStructureReady || isLoadingFullInfo)
      return forbidPlay(ALLOWANCE_TO_PLAY_STATUS.LOADING);

    if (!isSuccessLocalstorage || !isSuccessTransactions || !isSuccessFullInfo)
      return forbidPlay(ALLOWANCE_TO_PLAY_STATUS.FETCH_ERROR);

    if (fullInfo?.level === 0) return forbidPlay(ALLOWANCE_TO_PLAY_STATUS.ZERO_USER_LEVEL);

    const datesObject: DaysObjectStructure = JSON.parse(value);
    const currentDayOfMonth = dayjs().date();
    const currentDateObject = datesObject[currentDayOfMonth];

    if (!currentDateObject?.attempts.length) return allowToPlay();

    if (currentDateObject.attempts.length === 6)
      return forbidPlay(ALLOWANCE_TO_PLAY_STATUS.REACHED_ATTEMPTS_LIMIT);

    const isFullfillingDepositPresents = checkForFullfillingDepositInTransactions(
      transactions.transactions,
      currentDateObject,
      currencyName
    );

    if (isFullfillingDepositPresents) {
      return allowToPlay();
    }
    return forbidPlay(ALLOWANCE_TO_PLAY_STATUS.NO_TRANSACTION_AFTER_TIMESTAMP);
  }, [
    currencyName,
    fullInfo,
    isAuthenticated,
    isFetching,
    isLoadingFullInfo,
    isLoadingTransaction,
    isStructureReady,
    isSuccessFullInfo,
    isSuccessLocalstorage,
    isSuccessTransactions,
    transactions,
    value
  ]);

  return allowanceToPlay;
};
