import { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from 'src/components/Buttons/Button';
import { Separator } from 'src/components/Separator/Separator';
import { AccountIcon, LiveChatIcon } from 'src/components/svg';
import { useDrawer } from 'src/hooks/useDrawer';

import {
  BonusesResponse,
  useApiContext,
  useGetLocalTournaments,
  useGetPromo,
  useGetProviderTournaments,
  useWindowSize
} from 'react-easyrocket';
import { LanguagesSelect } from 'src/components/LanguagesSelect';
import { PWACards } from 'src/components/PWACards/PWACards';
import SidebarButton from 'src/components/SidebarButton';
import SidebarGamesDropdown from 'src/components/SidebarGamesDropdown';
import { sidebarLinks, sportLinks } from 'src/components/SidebarGamesDropdown/config';
import SidebarUserInfo from 'src/components/SidebarUserInfo';
import { sidebarBanners } from 'src/constants';
import { getRandomInt } from 'src/utils';
import { useModalContext } from 'src/hooks/useModalContext';
import { ModalMapKeys } from 'src/newUseModalContext/constants';
import {
  sidebarCasinoOption,
  sidebarJackpotOption,
  sidebarLiveOption
} from 'src/constants/gameCategories';
import { openChat } from 'src/components/LiveChatWidget/utils';
import { SidebarGameBannerArrow } from 'src/components/svg/SidebarGameBannerArrow';
import { SportSwitch } from '../SportSwitch';
import { GameBeerLink } from 'src/pages/GameBeer/components/NavButton';
import { DailyBonusButton } from 'src/modules/GPT/GPTUi/DailyBonusButton';
import { GPTWheelOfFortuneButton } from 'src/modules/GPT/GPTUi/GPTWheelButton';
import { SmarticoButtons } from './components/SmarticoButtons';

// import PromoLink from 'src/event/components/PromoLink/PromoLink';
// import PromoLink from '../../event/components/PromoLink/PromoLink';

const bonusTypes = {
  0: 'Registration',
  3: 'PromoCode',
  5: 'Deposits',
  6: 'Periodic',
  7: 'IncreaseWinnings',
  8: 'ActivatedByLink'
};

const SidebarContent = () => {
  const { isAuthenticated } = useApiContext();
  const { openModal } = useModalContext();
  const navigate = useNavigate();
  const { isMobileLayout } = useWindowSize();
  const { t } = useTranslation();
  const { closeLeft, closeNotifications } = useDrawer();
  const { data: bonusesData, isFetched } = useGetPromo();
  const bonusesToSort = bonusesData as BonusesResponse;
  const { tournaments: providerTournaments } = useGetProviderTournaments();
  const { tournaments: localTournaments } = useGetLocalTournaments();

  const handleOpenModal = useCallback(
    ({
      modalName,
      navigateToProfile = false
    }: {
      modalName?: ModalMapKeys;
      navigateToProfile?: boolean;
    }) => {
      if (navigateToProfile) {
        navigate?.('/account/profile/main');
        if (isMobileLayout) {
          closeLeft();
          closeNotifications();
        }
        return;
      }

      modalName && openModal(modalName);
      if (isMobileLayout) {
        closeLeft();
        closeNotifications();
      }
    },
    [openModal, isMobileLayout, navigate, closeLeft, closeNotifications]
  );

  const handleLiveChat = () => {
    openChat();
    if (isMobileLayout) {
      closeLeft();
      closeNotifications();
    }
  };

  const bonuses = useMemo(() => {
    return bonusesToSort?.filter((bonus) => {
      return !!(
        bonus.type in bonusTypes &&
        bonus.fullDescription &&
        bonus.shortDescription &&
        bonus.imageBanner &&
        bonus.imageBackground
      );
    });
  }, [bonusesToSort]);

  const tournamentsCount = useMemo(
    () =>
      providerTournaments || localTournaments?.active
        ? (providerTournaments?.length || 0) + (localTournaments?.active.length || 0)
        : 0,
    [localTournaments?.active, providerTournaments]
  );

  const sidebarButtons = useMemo(() => {
    if (isAuthenticated) {
      return (
        <div className="w-full flex flex-col items-center gap-[10px] pb-[10px]">
          <Button
            className="text-background-fivefold"
            mode="button-secondary"
            onClick={() =>
              handleOpenModal({
                modalName: 'POPUP_DEPOSIT'
              })
            }>
            {t('general.deposit')}
          </Button>
          <div className="w-full flex items-center gap-1">
            <Button
              mode="button-transparent"
              className="flex-1"
              onClick={() =>
                handleOpenModal({
                  navigateToProfile: true
                })
              }
              leftIcon={<AccountIcon className="w-[26px] mr-[15px]" />}>
              <p>{t('general.account')}</p>
            </Button>
            {/* TODO раcкомментить после релиза */}
            {/* <NotificationButton /> */}
          </div>
        </div>
      );
    }

    if (!isAuthenticated && isMobileLayout) return null;

    return (
      <div className="w-full flex flex-col items-center gap-[10px] pb-[10px]">
        <Button
          className="!text-background-fivefold"
          mode="button-secondary"
          onClick={() =>
            handleOpenModal({
              modalName: 'SIGNUP'
            })
          }>
          {t('general.signUp')}
        </Button>
        <Button
          mode="button-transparent"
          onClick={() =>
            handleOpenModal({
              modalName: 'LOGIN'
            })
          }>
          {t('general.logIn')}
        </Button>
      </div>
    );
  }, [isAuthenticated, isMobileLayout, handleOpenModal, t]);

  const { link, image, bannerTitle } = useMemo(() => {
    const numberBanner = getRandomInt(3);
    return sidebarBanners[numberBanner];
  }, []);

  return (
    <div className="w-full flex flex-col gap-5 items-center">
      {sidebarButtons}
      <SidebarUserInfo />
      <Separator width="w-full" noMarginTop />
      <SportSwitch
        onClick={() => {
          if (isMobileLayout) {
            closeLeft();
          }
        }}
      />
      <SmarticoButtons />
      <div className="w-full flex flex-col items-center gap-[20px]">
        <Separator width="w-full" noMarginTop />
        <GameBeerLink />
        <SidebarGamesDropdown option={sidebarCasinoOption} />
        <SidebarGamesDropdown option={sidebarLiveOption} />
        <SidebarGamesDropdown option={sidebarJackpotOption} />
        <Separator width="w-full" noMarginTop />
        {/* <PromoLink />
        <PromoLink shop={true} /> */}
        {sportLinks.map((link) => (
          <SidebarButton
            key={link.id}
            option={link}
            onClick={() => {
              openModal('SPORT_STUB_MODAL');
            }}
          />
        ))}
        <Separator width="w-full" noMarginTop />
        <DailyBonusButton />
        <GPTWheelOfFortuneButton />
        <SidebarButton option={sidebarLinks.jackpots} />
        {(Boolean(bonuses?.length) || !isFetched) && (
          <SidebarButton count={bonuses.length} option={sidebarLinks.bonuses} />
        )}
        <SidebarButton count={tournamentsCount} option={sidebarLinks.tournaments} />
        <SidebarButton option={sidebarLinks.vip} />
        <Separator width="w-full" noMarginTop />
        <button
          onClick={handleLiveChat}
          className="w-full flex items-center gap-[8px] text-[18px] font-bold 768:hover:text-yellow 768:[&_>svg>path]:hover:fill-yellow">
          <LiveChatIcon />
          <p>{t('mainSidebarLinks.liveChat')}</p>
        </button>
        <Separator width="w-full" noMarginTop />
      </div>
      <div className="w-full flex flex-col gap-[20px]">
        <Link
          className="cursor-pointer relative hover:scale-[1.04] transition rounded-[15px] pr-3 pl-[30%] h-[70px] border border-solid border-secondary bg-cover bg-no-repeat flex justify-end items-center"
          to={link}
          onClick={() => {
            if (isMobileLayout) {
              closeLeft();
              closeNotifications();
            }
          }}
          aria-label="banner"
          style={{ backgroundImage: `url(${image})` }}>
          <div className="flex justify-center items-center">
            <p className="text-base text-white font-bold first-letter:uppercase leading-5 mr-3 max-w-[100px]">
              {t(bannerTitle)}
            </p>
            <SidebarGameBannerArrow />
          </div>
        </Link>

        <button
          className="cursor-pointer relative hover:scale-[1.04] transition bg-[url('/src/assets/images/driver.webp')] rounded-[15px] pr-8 h-[70px] border border-solid border-secondary bg-cover bg-center bg-no-repeat flex justify-end items-center"
          onClick={() => {
            if (!isAuthenticated) {
              openModal('SIGNUP');
              return;
            }
            navigate('/game/15347-Boomerang');
            if (isMobileLayout) {
              closeLeft();
              closeNotifications();
            }
          }}>
          <div className="max-w-[100px] text-left font-bold">
            <p className="text-base text-white leading-4">{t('general.driver')}</p>
            <p className="text-sm text-yellow lowercase">{t('general.boomerang')}</p>
          </div>
        </button>
      </div>
      <Separator width="w-full" noMarginTop />
      <PWACards />
      <LanguagesSelect containerClassname="max-w-full mt-0 w-full" />
    </div>
  );
};

export default memo(SidebarContent);
