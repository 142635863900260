import dayjs from 'dayjs';
import { GetTransactionsQueryParamsAndOptionsTuple } from './types';
import { Transaction, makeFloat } from 'react-easyrocket';
import { GameDayStructure } from '../../types';
import { MIN_DEPOSIT_AMOUNT } from '../../config';

export const getTransactionsQueryParamsAndOptionsTurple: GetTransactionsQueryParamsAndOptionsTuple =
  () => [
    {
      date_start: dayjs.utc().format('YYYY-MM-DD'),
      date_end: dayjs.utc().add(1, 'day').format('YYYY-MM-DD'),
      type: ['Deposit'],
      page: 1
    },
    { disableCurrency: true, target: 'christmas-spins' }
  ];

export const allowToPlay = () => ({
  status: null,
  isAllowedToPlay: true
});

export const forbidPlay = (status: string) => ({
  status,
  isAllowedToPlay: false
});

export const checkForFullfillingDepositInTransactions = (
  transactions: Transaction[],
  currentDateObject: GameDayStructure,
  currencyName: string
) => {
  const newestDeps = transactions.filter(
    (transaction) =>
      transaction.dateCreate >
      parseInt(currentDateObject.attempts[currentDateObject.attempts.length - 1])
  );

  return newestDeps.some(
    (dep) => parseInt(makeFloat(dep.amount, 0)) >= MIN_DEPOSIT_AMOUNT[currencyName]
  );
};
