import { AnimatePresence, motion } from 'framer-motion';
import { RefObject, memo } from 'react';
import ModalWinGame from './ModalWinGame';
import { ModalUpLevel } from './ModalUpLevel';
import { CatchModal, CATCH_MODAL } from '../config';
import { MODAL_OVERLAY_ANIMATION } from '../animations';
import ModalPlayGame from './ModalPlayGame';
import ModalEndGame from './ModalEndGame';
import ModalLossGame from './ModalLossGame';
import ModalLostAllChances from './ModalLostAllChances';

interface CatchModalControllerProps {
  activeModal: CatchModal;
  winModalRef?: RefObject<HTMLDivElement>;
  handlePlayGame: () => void;
  handleGetReward: () => void;
}

const SantaModalController = ({
  winModalRef,
  activeModal,
  handlePlayGame,
  handleGetReward
}: CatchModalControllerProps) => {
  const isPlayGameModalActive = activeModal === CATCH_MODAL.PLAY_GAME;
  const isWinGameModalActive = activeModal === CATCH_MODAL.WIN_GAME;
  const isEndGameModalActive = activeModal === CATCH_MODAL.END_GAME;
  const isUpLevelModalActive = activeModal === CATCH_MODAL.UP_LEVEL;
  const isLossGameModalActive = activeModal === CATCH_MODAL.LOSS_GAME;
  const isLostAllChancesModalActive = activeModal === CATCH_MODAL.LOST_ALL_CHANCES;

  return (
    <AnimatePresence>
      {activeModal && (
        <motion.div
          className="absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center bg-[rgba(0,0,0,0.6)] z-[6]"
          {...MODAL_OVERLAY_ANIMATION}>
          {isPlayGameModalActive && <ModalPlayGame handlePlayGame={handlePlayGame} />}
          {isWinGameModalActive && (
            <ModalWinGame winModalRef={winModalRef} handleGetReward={handleGetReward} />
          )}
          {isEndGameModalActive && <ModalEndGame />}
          {isUpLevelModalActive && <ModalUpLevel />}
          {isLossGameModalActive && <ModalLossGame />}
          {isLostAllChancesModalActive && <ModalLostAllChances />}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default memo(SantaModalController);
