import { FC } from 'react';

interface PlayCardProps {
  title: string;
  description: string;
}

export const PlayCard: FC<PlayCardProps> = ({ title, description }) => {
  return (
    <article className="px-2.5 py-5 rounded-2xl bg-background-hexademical flex-none w-[180px]">
      <h3 className="text-[16px] font-bold leading-[110%] text-white">{title}</h3>
      <p className="mt-3 text-xs text-white">{description}</p>
    </article>
  );
};
