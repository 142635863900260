import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetSportId } from '../useGetSportId';
import { useGetSportBrandId } from 'react-easyrocket';
import { prepareSportUrl } from './utils/prepareSportUrl';

export const useSportUrl = () => {
  const { i18n } = useTranslation();
  const sportId = useGetSportId();

  const { data } = useGetSportBrandId({ 'sport-id': sportId }, {});

  const sportUrl = useMemo(() => {
    return prepareSportUrl(data?.data?.token, i18n.language);
  }, [data?.data?.token, i18n.language]);

  return sportUrl;
};
