import { CONTENT_URL } from 'src/api';
import { CSSProperties } from 'react';

type Image = {
  imageDesktop: string;
  imageMobile: string;
};

type CreateStylesReturn = { style: CSSProperties; desktopBg: string; mobileBg: string };

export const createStyles = ({ imageMobile, imageDesktop }: Image): CreateStylesReturn => {
  const style: CSSProperties = {
    '--image-desktop': `url(${CONTENT_URL}${imageDesktop})`,
    '--image-mobile': `url(${CONTENT_URL}${imageMobile})`
  } as CSSProperties;

  const desktopBg = 'bg-[image:var(--image-mobile)]';
  const mobileBg = '768:bg-[image:var(--image-desktop)]';

  return { style, desktopBg, mobileBg };
};
