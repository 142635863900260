export const DeactivateFullScreen = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.4998 0.0899658L17.9098 1.49997L13.4098 5.99997H16.9998V7.99997H9.99984V0.999966H11.9998V4.58997L16.4998 0.0899658ZM17.9098 16.5L16.4998 17.91L11.9998 13.41V17H9.99984V9.99997H16.9998V12H13.4098L17.9098 16.5ZM1.49984 0.0899658L5.99984 4.58997V0.999966H7.99984V7.99997H0.999844V5.99997H4.58984L0.0898438 1.49997L1.49984 0.0899658ZM0.0898438 16.5L4.58984 12H0.999844V9.99997H7.99984V17H5.99984V13.41L1.49984 17.91L0.0898438 16.5Z"
        fill="white"
      />
    </svg>
  );
};

export const ActivateFullScreen = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.5 10.09L7.91 11.5L3.41 16H7V18H0V11H2V14.59L6.5 10.09ZM7.91 6.5L6.5 7.91L2 3.41V7H0V0H7V2H3.41L7.91 6.5ZM11.5 10.09L16 14.59V11H18V18H11V16H14.59L10.09 11.5L11.5 10.09ZM10.09 6.5L14.59 2H11V0H18V7H16V3.41L11.5 7.91L10.09 6.5Z"
        fill="white"
      />
    </svg>
  );
};

export const DeactivateFullScreenIcon = () => {
  return (
    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_15725_205375)">
        <path
          d="M13.4898 29.7439C13.0586 29.7439 12.7086 29.3946 12.7086 28.9626V22.0728H5.78125C5.35 22.0728 5 21.7235 5 21.2915C5 20.8595 5.35 20.5103 5.78125 20.5103H13.4898C13.9211 20.5103 14.2711 20.8595 14.2711 21.2915V28.9626C14.2711 29.3946 13.9211 29.7439 13.4898 29.7439ZM21.5102 29.7243C21.0789 29.7243 20.7289 29.3751 20.7289 28.9431V21.2337C20.7289 20.8017 21.0789 20.4524 21.5102 20.4524H29.1812C29.6125 20.4524 29.9625 20.8017 29.9625 21.2337C29.9625 21.6657 29.6125 22.0149 29.1812 22.0149H22.2914V28.9431C22.2914 29.3751 21.9414 29.7243 21.5102 29.7243ZM13.4898 14.5478H5.81875C5.3875 14.5478 5.0375 14.1985 5.0375 13.7665C5.0375 13.3345 5.3875 12.9853 5.81875 12.9853H12.7086V6.05713C12.7086 5.6251 13.0586 5.27588 13.4898 5.27588C13.9211 5.27588 14.2711 5.6251 14.2711 6.05713V13.7665C14.2711 14.1978 13.9211 14.5478 13.4898 14.5478ZM29.2188 14.4899H21.5102C21.0789 14.4899 20.7289 14.1407 20.7289 13.7087V6.0376C20.7289 5.60557 21.0789 5.25635 21.5102 5.25635C21.9414 5.25635 22.2914 5.60557 22.2914 6.0376V12.9274H29.2188C29.65 12.9274 30 13.2767 30 13.7087C30 14.1407 29.65 14.4899 29.2188 14.4899Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_15725_205375">
          <rect width="25" height="25" fill="white" transform="translate(5 5)" />
        </clipPath>
      </defs>
    </svg>
  );
};
