import { NavLink } from 'react-router-dom';
import Beer from './assets/beer_icon.webp';
import { twJoin } from 'tailwind-merge';
import { FC } from 'react';
import { shouldShowOktoberfestCategory } from 'src/OktoberfestEvent/dates';
import { useDrawer } from 'src/hooks/useDrawer';

interface GameBeerLinkProps {
  className?: string;
  isCollapsed?: boolean;
}

export const GameBeerLink: FC<GameBeerLinkProps> = ({ className, isCollapsed }) => {
  const { closeLeft } = useDrawer();

  if (!shouldShowOktoberfestCategory) {
    return null;
  }
  return (
    <NavLink
      to="catch-the-beer"
      onClick={closeLeft}
      className={({ isActive }) =>
        twJoin(
          'bg-OKTOBERFEST_LINK rounded-lg flex  items-center gap-2.5 text-base font-bold hover:text-yellow',
          isCollapsed ? 'p-[7px]' : 'px-2 py-0.5 w-full',
          isActive ? 'text-yellow' : 'text-white',
          className
        )
      }>
      <img
        src={Beer}
        alt="cup of beer"
        width={40}
        height={40}
        className={isCollapsed ? 'w-[30px] h-[30px]' : 'w-10 h-10'}
      />
      {!isCollapsed && 'Catch the beer'}
    </NavLink>
  );
};
