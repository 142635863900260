import { SmarticoButton } from 'src/components/SmarticoButton';
import { smarticoButtons } from '../../constants';
import { useTranslation } from 'react-i18next';
import { Separator } from 'src/components/Separator/Separator';
import { useApiContext, useGetFullInfo } from 'react-easyrocket';

export const SmarticoButtons = () => {
  const { t } = useTranslation();

  // TODO: remove as Smartico will work
  const { isAuthenticated } = useApiContext();
  const { fullInfo } = useGetFullInfo();

  if (!isAuthenticated || !fullInfo) return null;
  if (!fullInfo.email.includes('__smartico.test')) return null;

  return (
    <>
      <Separator width="w-full" noMarginTop />
      <div className="grid grid-cols-2 gap-y-2 gap-x-1 w-full">
        {smarticoButtons.map(({ key, label, icon, isSoonLabel, action, navigate }, index) => (
          <SmarticoButton
            key={key}
            label={t(label)}
            icon={icon}
            isSoonLabel={isSoonLabel}
            action={action}
            navigate={navigate}
            className={index === 0 ? 'col-span-full' : undefined}
          />
        ))}
      </div>
    </>
  );
};
