import React, { lazy, useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import {
  DepositSuccessRedirect,
  useApiContext,
  useGetBlockedCountry,
  useGetDepositMethods,
  useTrackReferralLink
} from 'react-easyrocket';

import Cookies from 'src/components/CookiesBanner';
import { resetPreloadRetries } from 'src/utils/preloadRetry';
import { smarticoLogout } from 'src/utils/smartico';
import { SocialSignUpHandler } from 'src/components/SocialSignUpHandler';
import { useCentrifugeMessages } from 'src/hooks/useCentrifugeMessages';
import { BlockedCountryPlug } from 'src/components/BlockedCountryPlug/BlockedCountryPlug';
import { FreeSpinsHandler } from 'src/components/FreeSpinsHandler';
import { Layout } from 'src/components/Layout';
import { Modal } from 'src/components/Modal';
import { useJackpotWin } from 'src/components/Modal/JackpotWinModal/useJackpotWin';
import { OpenSingnUpByLink } from 'src/components/OpenSingnUpByLink';
import { PageHead } from 'src/components/PageHead';
import { ProtectedAuthRoute } from 'src/components/ProtectedAuthRoute/ProtectedAuthRoute';
import { useDoSomethingOnEveryPage } from 'src/hooks/useDoSomethingOnEveryPage';
import { useFullScreen } from 'src/hooks/useFullScreen';
import { useOpenDepositOnSessionStart } from 'src/hooks/useOpenDepositOnSessionStart';
import { useTournamentWin } from 'src/hooks/useTournamentWin';
import { useTrackIncomingBonusTransaction } from 'src/hooks/useTrackIncomingBonusTransaction';
import { LandingWheelOfFortune } from 'src/pages/LandingWheelOfFortune';
import { LocalTournament } from 'src/pages/LocalTournament';
import { MobileLanding } from 'src/pages/MobileLanding';
import { SendAction } from 'src/components/SendAction';
import { ProgressiveTooltip } from 'src/components/ProgressiveTooltip/ProgressiveTooltip';
import { useModalContext } from 'src/hooks/useModalContext';
import { twMerge } from 'tailwind-merge';

import { Landing } from 'src/pages/Landing';
import { MainPage } from 'src/pages/Main';
import { useActivateAuthBonus } from 'src/hooks/useActivateAuthBonus';
import { ActivateBonus } from 'src/pages/ActivateBonus';
import { LiveChatWidget } from 'src/components/LiveChatWidget';
import { MessagesContainer } from 'src/modules/messages/components/MessagesContainer';
// TODO: uncomment FE-5509
// import { useSetUserLanguage } from 'src/hooks/useSetUserLanguage';
// import { SportSwissPage } from 'src/pages/SportSwiss';
import { GameBeer } from 'src/pages/GameBeer';
import { EventNotification } from 'src/pages/GameBeer/components/EventNotification';
import { shouldShowOktoberfestCategory } from 'src/OktoberfestEvent/dates';
import { SportSwissPage } from 'src/pages/SportSwiss';
import { useSetSmarticoUser } from 'src/hooks/useSetSmarticoUser';

const Bonus = lazy(() => import('src/pages/Bonus'));
const Bonuses = lazy(() => import('src/pages/Bonuses'));
const Jackpots = lazy(() => import('src/pages/Jackpots'));
const Tournaments = lazy(() => import('src/pages/Tournaments'));
const ContentPage = lazy(() => import('src/pages/ContentPage'));
const Faqs = lazy(() => import('src/pages/Faqs'));
const ProviderTournament = lazy(() => import('src/pages/ProviderTournament'));
const Games = lazy(() => import('src/pages/Games'));
const PwaPage = lazy(() => import('src/pages/PwaPage'));
const GameLayout = lazy(() => import('src/components/Layout/GameLayout'));
const GamePage = lazy(() => import('src/pages/Game'));
const AccountProfile = lazy(() => import('src/pages/AccountProfile'));
const AccountGameHistory = lazy(() => import('src/pages/AccountGameHistory'));
const AccountJackpots = lazy(() => import('src/pages/AccountJackpots'));
const AccountLayout = lazy(() => import('src/components/Layout/AccountLayout'));
const NewAccountVip = lazy(() => import('src/pages/NewAccountVip'));
const AccountWallet = lazy(() => import('src/pages/AccountWallet'));
const AccountPromo = lazy(() => import('src/pages/AccountPromo'));
const Vip = lazy(() => import('src/pages/Vip'));

// import { CatchGame } from 'src/pages/CatchGame';
// import { isShowCatchGame } from 'src/pages/CatchGame/helpers';
// import { EVENT_LINKS } from 'src/event/config';
// import EventPromoBanner from 'src/event/components/EventPromoBanner';
// import { isShowCardGame } from 'src/pages/CardGame/helpers';
// import CardGame from 'src/pages/CardGame';
// import ShopEvent from 'src/pages/ShopEvent';
// import ShopUnauthorizedEvent from 'src/pages/ShopEventUnauthorized';

export const Router = React.memo(() => {
  const { isAuthenticated } = useApiContext();
  const { refFullscreen } = useFullScreen();
  const { isBlockedCountry } = useGetBlockedCountry();
  const location = useLocation();
  const { openModal } = useModalContext();
  const isLanding = location.pathname.includes('landing');

  useDoSomethingOnMount();
  useDoSomethingOnEveryPage();
  useDoSomethingOnLogout();
  useSetSmarticoUser();

  useTrackIncomingBonusTransaction();
  useJackpotWin();
  useTournamentWin();
  useGetDepositMethods();
  useOpenDepositOnSessionStart();
  useTrackReferralLink({ openSignup: () => openModal('SIGNUP') });
  useCentrifugeMessages();
  useActivateAuthBonus();
  // TODO: uncomment FE-5509
  // useSetUserLanguage();

  // TODO раcкомментить после релиза
  // useNewsNotifications();

  if (isBlockedCountry) {
    return <BlockedCountryPlug />;
  }

  return (
    <>
      <main
        ref={refFullscreen}
        className={twMerge(
          'max-w-[2560px] m-auto w-full palette',
          isLanding && 'max-w-full min-h-full'
        )}
        id="Jacktop">
        <MessagesContainer />
        <Modal />
        <PageHead />
        <LiveChatWidget />
        <ProgressiveTooltip />
        {shouldShowOktoberfestCategory && <EventNotification />}
        <Routes>
          <Route
            path="/:lang?/account"
            element={
              <ProtectedAuthRoute>
                <AccountLayout />
              </ProtectedAuthRoute>
            }>
            <Route index element={<Navigate to={'/account/profile/main'} />} />
            <Route path="profile/*" element={<AccountProfile />} />
            <Route path="transaction-history/*" element={<AccountGameHistory />} />
            <Route path="wallet/*" element={<AccountWallet />} />
            <Route path="jackpots" element={<AccountJackpots />} />
            <Route path="promo" element={<AccountPromo />} />
            <Route path="vip" element={<NewAccountVip />} />
          </Route>

          <Route path="/:lang?" element={<Layout />}>
            {/* SPORT START */}
            <Route path="test-sport-url/*" element={<SportSwissPage />} />
            {/* SPORT END */}
            <Route
              path="wheel-of-fortune"
              element={isAuthenticated ? <Navigate to={'/'} /> : <LandingWheelOfFortune />}
            />
            <Route index element={<MainPage />} />
            <Route path="auth" element={<SocialSignUpHandler />} />
            <Route path="games/:categoryId?" element={<Games />} />
            <Route path="jackpots" element={<Jackpots />} />
            {/* <Route path="halloween" element={<Halloween />} /> */}
            <Route path="tournaments" element={<Tournaments />} />

            <Route path="tournaments/:tournamentCode" element={<ProviderTournament />} />
            <Route path="tournaments/local/:tournamentId" element={<LocalTournament />} />
            <Route path="bonuses" element={<Bonuses />} />
            <Route path="bonuses/:bonus" element={<Bonus />} />
            <Route path="info_pages/:page" element={<ContentPage />} />
            <Route path="faq" element={<Faqs />} />
            <Route path="pwa-instruction/:deviceId?" element={<PwaPage />} />
            <Route path="vip" element={<Vip />} />
            <Route
              path="catch-the-beer"
              element={shouldShowOktoberfestCategory ? <GameBeer /> : <Navigate to="/" />}
            />
            {/* <Route
              path="shop"
              element={isAuthenticated ? <ShopEvent /> : <ShopUnauthorizedEvent />}
            />
            <Route
              path={EVENT_LINKS.CATCH_GAME}
              element={isShowCatchGame() ? <CatchGame /> : <Navigate to="/" />}
            />
            <Route
              path={EVENT_LINKS.CARD_GAME}
              element={isShowCardGame() ? <CardGame /> : <Navigate to="/" />}
            /> */}
          </Route>

          <Route path="/:lang?/game" element={<GameLayout />}>
            <Route index element={<Navigate to={'/games'} />} />
            <Route path=":gameIdAndName/:demo?" element={<GamePage />} />
          </Route>

          <Route
            path="/:lang?/deposit-success/:merchantId?"
            element={<DepositSuccessRedirect redirectTo="/account/wallet/history" />}
          />
          <Route
            path="/:lang?/deposit-failure/*"
            element={<Navigate to={'/account/wallet/history'} />}
          />
          <Route
            path="/:lang?/deposit-return/:merchantId?"
            element={<DepositSuccessRedirect redirectTo="/account/wallet/history" />}
          />
          <Route
            path="/:lang?/welcome-bonus"
            element={isAuthenticated ? <Navigate to={'/'} /> : <MobileLanding />}
          />
          <Route
            path="/:lang?/landing/welcome-bonus"
            element={isAuthenticated ? <Navigate to={'/'} /> : <Landing />}
          />
          <Route path="/:lang?/activate-bonus/:bonusId" element={<ActivateBonus />} />
          {/* TODO добавить 404 сюда */}
          <Route path="/action" element={<SendAction />} />
          <Route path="*" element={<Navigate to={'/'} />} />
        </Routes>
        <Cookies />
        {/* <EventPromoBanner /> */}
        <FreeSpinsHandler />
        <OpenSingnUpByLink />
      </main>
    </>
  );
});

Router.displayName = 'Router';

function useDoSomethingOnMount() {
  useEffect(() => {
    resetPreloadRetries();
  }, []);
}

function useDoSomethingOnLogout() {
  const { isAuthenticated } = useApiContext();

  useEffect(() => {
    if (!isAuthenticated) {
      smarticoLogout();
    }
  }, [isAuthenticated]);
}
