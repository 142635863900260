import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from '../Buttons/Button';
import { prepareGameUrl } from 'src/utils';
import { GameData, MESSAGE_STATUS, useApiContext, useGetFullInfo } from 'react-easyrocket';
import { useModalContext } from 'src/hooks/useModalContext';
import { getJackpotOfGameIfParticipates, makeJackpotQueryParameter } from './utils';
import { getAccountInfoErrorMessage } from './utils/getAccountInfoErrorMessage';
import { twJoin } from 'tailwind-merge';
import { CardImage } from './components/CardImage';
import { LightGameBadges } from './components/LightGameBadges';
import { useMessage } from 'src/modules/messages';
import classes from './styles.module.css';

interface GameProps {
  game: GameData;
  isWagering?: boolean;
  className?: string;
  showGameInfo?: boolean;
  navigationState?: string;
  hideBadges?: boolean;
  descriptionContainerClassname?: string;
  onGameClick?: () => void;
  queryTitle?: string;
  providerTextClassName?: string;
  titleClassName?: string;
}

export const GameCard: FC<GameProps> = ({
  game,
  className,
  queryTitle,
  descriptionContainerClassname,
  titleClassName,
  onGameClick,
  providerTextClassName
}) => {
  const { t } = useTranslation();
  const { fullInfo } = useGetFullInfo();
  const navigate = useNavigate();
  const { openMessage } = useMessage();
  const { openModal } = useModalContext();
  const { pathname, search } = useLocation();
  const { isAuthenticated } = useApiContext();

  const queryParameters = useMemo(() => {
    return makeJackpotQueryParameter(getJackpotOfGameIfParticipates(game as GameData));
  }, [game]);

  if (!game.image) {
    return null;
  }

  const navigateToGame = ({ isDemo }: { isDemo: boolean }) => {
    const gameUrl = prepareGameUrl(game.id, game.name);
    const searchParam =
      queryParameters || (queryTitle ? `&jackpot=${queryTitle?.toLowerCase()}` : '');

    navigate(`${gameUrl}${isDemo ? '/demo' : ''}?${searchParam}`, {
      state: { history: `${pathname}${search}` }
    });
  };

  const handlePlayGameClick = () => {
    if (!isAuthenticated) {
      openModal('SIGNUP', { props: { hideWhyNot: true } });
      return;
    }

    const errorMessage = getAccountInfoErrorMessage(game.providerId, fullInfo);

    if (errorMessage) {
      openMessage({
        type: 'USER_MESSAGE',
        props: { text: t(errorMessage), type: MESSAGE_STATUS.ERROR },
        saveToHistory: false
      });
      return;
    }
    onGameClick?.();
    navigateToGame({ isDemo: false });
  };

  const handlePlayDemoGameClick = () => {
    navigateToGame({ isDemo: true });
    onGameClick?.();
  };

  return (
    <div className={twJoin('game-card', classes['game-card'], className)}>
      <CardImage imageUrl={game.image} />
      <LightGameBadges game={game} />

      <div className={twJoin('controls', classes['card-content'], descriptionContainerClassname)}>
        <div className="flex-col gap-1">
          <p className={twJoin(classes['card-name'], titleClassName)}>{game.name}</p>
          <p className={twJoin(classes['card-provider'], providerTextClassName)}>
            {game.providerName}
          </p>
        </div>
        <Button
          onClick={handlePlayGameClick}
          title={`${t('general.play')}`}
          mode="button-secondary"
          className={classes['play-btn']}>
          {t('general.play')}
        </Button>
        {game.hasDemo && (
          <Button
            onClick={handlePlayDemoGameClick}
            title={`${t('general.playDemo')}`}
            mode="button-transparent"
            className={classes['provider-btn']}>
            {t('general.playDemo')}
          </Button>
        )}
      </div>
    </div>
  );
};
