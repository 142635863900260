import classNames from 'classnames';
import { memo, useEffect, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';

import logo from 'src/assets/images/logoSidebar.webp';
import { useClickOutside } from 'src/hooks/useClickOutside';
import { useDrawer } from 'src/hooks/useDrawer';
import { useScrollToTopOnMount } from 'src/hooks/useScrollToTop';
import SidebarContent from './SidebarContent';
import { useWindowSize } from 'react-easyrocket';
import { replaceWithOktoberfestBigLogo } from 'src/event/oktoberfest/changeDefaultLogo';
import { NotificationsDrawer } from './components/Notifications/NotificationsDrawer';
import { SidebarArrow } from '../svg';
import { useSportContext } from 'src/context/SportProvider';

const SIDEBAR_STYLE =
  'z-[750] flex flex-col items-center fixed top-0 h-full w-[270px] pt-[25px] pb-[84px] transition-all duration-300 overflow-y-auto overflow-x-hidden text-text-secondary';

const _Sidebar = () => {
  const { isLeftOpen, closeLeft, isTopDrawerOpen, isNotificationsOpen, closeNotifications } =
    useDrawer();
  const { pathname } = useLocation();
  const { isMobileLayout, is1280, is768 } = useWindowSize();
  const { isSportPage } = useSportContext();
  const isGamePage = useMemo(() => pathname.includes('/game/'), [pathname]);

  useEffect(() => {
    // Этот эффект закрывает сайдбар, если юзер открывает поиск по играм (topDrawer)
    if (isTopDrawerOpen) {
      closeNotifications();
      closeLeft();
    }
  }, [isTopDrawerOpen]);

  const sidebarRef = useScrollToTopOnMount([isLeftOpen]);

  useClickOutside(sidebarRef, () => {
    if (isNotificationsOpen) return;
    closeLeft();
  });

  const handleCloseSidebar = () => {
    if (isNotificationsOpen && isLeftOpen && !is768) {
      closeNotifications();
      closeLeft();
    }
    closeLeft();
  };

  // TODO разбить на больше компонентов, т.к. при открытии/закрытии в перерисовке нуждается только внешний див,
  // а остальные элементы остаются неизменными
  return (
    <>
      <div
        className={classNames(
          SIDEBAR_STYLE,
          'bg-background-sidebar',
          isLeftOpen ? 'translate-x-0' : 'translate-x-[-270px]'
        )}
        ref={sidebarRef}>
        <div
          onClick={handleCloseSidebar}
          className={classNames(
            'cursor-pointer w-4/5 flex items-center mb-[30px] transition-transform duration-300',
            isLeftOpen ? 'justify-end' : 'justify-center'
          )}>
          <SidebarArrow className={'rotate-180'} />
        </div>
        {!isMobileLayout && (
          <Link to="/" aria-label="back to main" onClick={() => !is1280 && closeLeft()}>
            <div className="max-w-[155px] mb-5">
              <img src={replaceWithOktoberfestBigLogo(logo)} alt="jacktop logo" />
            </div>
          </Link>
        )}
        <div className="w-full px-3">
          <SidebarContent key="sidebar-content" />
        </div>
      </div>

      {/** Этот div накрывает фрейм игры когда сайдбар открыт, чтобы useClickOutside мог работать.
       * Иначе фрейм игры перехватывает клики на себя и не даёт им всплывать
       */}
      {(isGamePage || isSportPage) && isLeftOpen && (
        <div className="fixed w-screen h-screen top-0 left-0 z-10" />
      )}

      {isNotificationsOpen && <NotificationsDrawer />}
    </>
  );
};

export const Sidebar = memo(_Sidebar);
