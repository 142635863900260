import { memo } from 'react';
import usedAttempt from '../images/gameFrame/usedAttemp.webp';
import unusedAttempt from '../images/gameFrame/unusedAttemp.webp';
import classNames from 'classnames';
import { useDeviceDetect } from 'react-easyrocket';

interface AttemptsProps {
  attempts: number;
}

const Attempts = ({ attempts }: AttemptsProps) => {
  const unusedAttempts = Array.from({ length: attempts });
  const usedAttempts = Array.from({ length: 3 - attempts });
  const { isMobile } = useDeviceDetect();

  return (
    <div className="flex gap-[10px] 768:gap-[20px] w-full justify-center mb-[10px] mt-2">
      {usedAttempts.map((_, i) => {
        return (
          <img
            src={usedAttempt}
            key={i}
            alt="used attemp"
            className={classNames(
              'h-[40px] w-[40px] object-contain',
              !isMobile && '768:w-[50px] 768:h-[50px]'
            )}
          />
        );
      })}
      {unusedAttempts.map((_, i) => {
        return (
          <img
            src={unusedAttempt}
            key={i}
            alt="unused attemp"
            className={classNames(
              'h-[40px] w-[40px] object-contain',
              !isMobile && '768:w-[50px] 768:h-[50px]'
            )}
          />
        );
      })}
    </div>
  );
};

export default memo(Attempts);
