import { CheckEventDatesParams, checkEventDatesFromEnv } from 'src/utils/checkEventDates';

export const END_OF_OKTOBERFEST_DATE = {
  year: '2024',
  month: '10',
  day: '02'
};

export const OKTOBERFEST_CATEGORY_DATES_DEV: CheckEventDatesParams = {
  startDate: {
    year: '2024',
    month: '09',
    day: '12'
  },
  endDate: END_OF_OKTOBERFEST_DATE
};

export const OKTOBERFEST_CATEGORY_DATES_PROD: CheckEventDatesParams = {
  startDate: {
    year: '2024',
    month: '09',
    day: '23'
  },
  endDate: END_OF_OKTOBERFEST_DATE
};

export const shouldShowOktoberfestCategory = checkEventDatesFromEnv({
  devDates: OKTOBERFEST_CATEGORY_DATES_DEV,
  prodDates: OKTOBERFEST_CATEGORY_DATES_PROD
});
