import classNames from 'classnames';
import { memo } from 'react';
import { DeactivateFullScreenIcon } from 'src/components/svg/FullScreens';
import { useFullScreen } from 'src/hooks/useFullScreen';

const FullScreenButton = () => {
  const { isFullscreenGameBoard, onFullscreenClose, onFullscreenOpen, isHideHUD } = useFullScreen();

  return isFullscreenGameBoard || isHideHUD ? (
    <button
      onClick={onFullscreenClose}
      className={classNames('absolute top-[5px] right-[5px] z-[2]')}>
      <DeactivateFullScreenIcon />
    </button>
  ) : (
    <button
      className="font-bold text-[15px] leading-[18px] uppercase underline text-system-100 flex gap-[5px] items-center absolute top-[5px] right-[5px] z-[5]"
      onClick={onFullscreenOpen}>
      <DeactivateFullScreenIcon />
    </button>
  );
};

export default memo(FullScreenButton);
